const syllables_ru = [
  ['ё', 291, 3, 218, 70],
  ['жик', 13, 0, 1, 12],
  ['ка', 5103, 826, 958, 3319],
  ['ло', 2196, 214, 1859, 123],
  ['чка', 747, 0, 3, 744],
  ['ём', 91, 1, 69, 21],
  ['кость', 73, 0, 0, 72],
  ['ра', 3681, 1679, 1507, 495],
  ['ёр', 40, 2, 13, 25],
  ['ник', 480, 0, 0, 479],
  ['шик', 5, 0, 0, 4],
  ['а', 3201, 2214, 969, 18],
  ['кон', 538, 393, 106, 38],
  ['то', 2268, 246, 1972, 50],
  ['ось', 10, 6, 0, 3],
  ['ба', 906, 411, 374, 121],
  ['жур', 24, 10, 11, 3],
  ['чик', 163, 0, 0, 163],
  ['баз', 5, 0, 0, 4],
  ['зин', 83, 0, 34, 49],
  ['зи', 630, 27, 599, 3],
  ['нец', 458, 0, 1, 457],
  ['я', 5285, 176, 916, 4193],
  ['бак', 14, 0, 0, 13],
  ['кан', 172, 62, 80, 30],
  ['бан', 82, 46, 17, 19],
  ['дон', 62, 11, 24, 26],
  ['ти', 1965, 122, 1829, 13],
  ['са', 1069, 744, 271, 54],
  ['сса', 103, 3, 56, 44],
  ['тство', 80, 0, 10, 70],
  ['ви', 1254, 352, 895, 7],
  ['ту', 498, 142, 355, 1],
  ['ци', 1957, 148, 1808, 1],
  ['бди', 9, 1, 8, 0],
  ['мен', 174, 19, 127, 28],
  ['бду', 15, 0, 15, 0],
  ['ктор', 133, 0, 23, 110],
  ['кци', 190, 0, 190, 0],
  ['хай', 9, 2, 2, 5],
  ['бе', 966, 586, 377, 3],
  ['лит', 160, 0, 0, 160],
  ['рра', 36, 0, 28, 8],
  ['би', 881, 316, 556, 9],
  ['джа', 34, 5, 22, 7],
  ['е', 13750, 193, 892, 12664],
  ['тин', 134, 5, 59, 70],
  ['сси', 215, 0, 214, 1],
  ['ри', 1580, 113, 1432, 35],
  ['ент', 14, 0, 2, 12],
  ['ен', 52, 1, 44, 7],
  ['тка', 555, 5, 27, 523],
  ['бла', 188, 110, 74, 4],
  ['кти', 184, 1, 183, 0],
  ['ро', 3625, 268, 3324, 33],
  ['вка', 904, 3, 13, 888],
  ['тив', 56, 0, 1, 55],
  ['бля', 13, 3, 7, 3],
  ['ут', 26, 0, 0, 26],
  ['бо', 1131, 281, 836, 14],
  ['ли', 2222, 330, 1870, 22],
  ['о', 6107, 3303, 2779, 25],
  ['низм', 157, 0, 0, 157],
  ['нист', 122, 0, 0, 122],
  ['не', 2145, 1400, 735, 10],
  ['мент', 82, 0, 1, 80],
  ['нент', 14, 0, 0, 14],
  ['нен', 13, 1, 11, 1],
  ['ни', 7833, 163, 7653, 17],
  ['ва', 3781, 213, 3432, 136],
  ['вань', 2349, 1, 2345, 3],
  ['бор', 137, 40, 69, 27],
  ['ген', 109, 16, 40, 52],
  ['борт', 5, 0, 0, 4],
  ['та', 1691, 280, 1023, 388],
  ['рий', 132, 0, 19, 113],
  ['бра', 403, 78, 313, 11],
  ['вность', 182, 0, 0, 182],
  ['да', 948, 101, 515, 332],
  ['бре', 116, 40, 74, 2],
  ['же', 643, 92, 542, 9],
  ['бри', 110, 25, 83, 1],
  ['кос', 18, 0, 0, 18],
  ['ко', 2384, 1028, 1327, 29],
  ['со', 1515, 722, 786, 7],
  ['те', 1493, 600, 880, 13],
  ['изм', 51, 0, 0, 51],
  ['ист', 36, 0, 0, 36],
  ['бси', 5, 0, 5, 0],
  ['бсо', 10, 0, 10, 0],
  ['лю', 280, 80, 199, 1],
  ['за', 2546, 1373, 940, 233],
  ['тизм', 113, 0, 0, 113],
  ['тист', 56, 0, 0, 56],
  ['тность', 219, 0, 0, 219],
  ['бсор', 7, 0, 7, 0],
  ['бер', 101, 29, 57, 14],
  ['бци', 11, 0, 11, 0],
  ['метр', 270, 0, 0, 269],
  ['бстра', 8, 0, 8, 0],
  ['ги', 1341, 459, 875, 7],
  ['ктность', 15, 0, 0, 15],
  ['бсур', 5, 0, 5, 0],
  ['дизм', 26, 0, 0, 26],
  ['дист', 23, 0, 0, 23],
  ['дность', 138, 0, 0, 138],
  ['до', 1774, 451, 1290, 33],
  ['лог', 184, 0, 0, 183],
  ['бу', 452, 248, 199, 5],
  ['ец', 182, 0, 0, 182],
  ['бий', 43, 0, 35, 8],
  ['зец', 18, 0, 0, 18],
  ['зка', 157, 0, 1, 156],
  ['ю', 185, 95, 89, 1],
  ['вал', 72, 12, 23, 36],
  ['лист', 127, 0, 0, 126],
  ['валь', 135, 16, 111, 8],
  ['ван', 85, 10, 58, 17],
  ['гар', 78, 44, 19, 15],
  ['зал', 15, 4, 3, 7],
  ['ме', 1513, 629, 878, 6],
  ['жа', 322, 51, 219, 52],
  ['порт', 19, 0, 0, 18],
  ['пост', 9, 0, 0, 8],
  ['си', 802, 300, 493, 8],
  ['сце', 50, 18, 32, 0],
  ['на', 2884, 988, 902, 994],
  ['таж', 35, 0, 0, 35],
  ['жность', 74, 0, 0, 74],
  ['тю', 60, 31, 29, 0],
  ['ризм', 64, 0, 0, 64],
  ['рин', 99, 1, 43, 55],
  ['рист', 78, 0, 0, 78],
  ['стка', 285, 0, 1, 284],
  ['тюр', 15, 7, 6, 2],
  ['ность', 780, 0, 0, 780],
  ['вар', 111, 14, 53, 43],
  ['рец', 122, 0, 0, 122],
  ['щик', 653, 0, 0, 653],
  ['тар', 59, 18, 34, 7],
  ['сти', 569, 68, 499, 2],
  ['вдо', 75, 11, 64, 0],
  ['ве', 1033, 336, 694, 3],
  ['ню', 32, 8, 19, 4],
  ['гре', 165, 48, 117, 0],
  ['гат', 18, 0, 0, 18],
  ['бен', 70, 38, 28, 4],
  ['лет', 39, 0, 0, 39],
  ['бом', 32, 23, 6, 3],
  ['вы', 991, 783, 206, 2],
  ['ста', 521, 178, 310, 33],
  ['го', 1532, 385, 1106, 40],
  ['зонт', 5, 0, 0, 4],
  ['груз', 8, 0, 0, 7],
  ['гру', 126, 52, 74, 0],
  ['ппа', 53, 0, 38, 15],
  ['дви', 77, 8, 69, 0],
  ['га', 1160, 339, 571, 249],
  ['тель', 1753, 9, 665, 1079],
  ['де', 1485, 642, 841, 2],
  ['сант', 10, 0, 0, 10],
  ['ди', 1895, 506, 1370, 18],
  ['спе', 264, 143, 121, 0],
  ['тчер', 9, 0, 1, 8],
  ['вод', 155, 0, 0, 154],
  ['зве', 161, 35, 126, 0],
  ['но', 2168, 201, 1923, 44],
  ['зон', 45, 6, 13, 26],
  ['и', 1889, 1060, 821, 8],
  ['му', 310, 180, 127, 2],
  ['ще', 541, 53, 299, 189],
  ['ство', 1049, 7, 93, 949],
  ['ин', 536, 402, 90, 44],
  ['нер', 197, 16, 109, 72],
  ['по', 3685, 2646, 1031, 8],
  ['стро', 310, 50, 255, 5],
  ['фа', 343, 165, 148, 29],
  ['ке', 240, 80, 155, 5],
  ['син', 114, 61, 37, 16],
  ['ком', 275, 134, 119, 21],
  ['па', 1300, 721, 515, 63],
  ['пас', 21, 0, 0, 20],
  ['стру', 88, 23, 65, 0],
  ['кры', 57, 16, 41, 0],
  ['лай', 19, 6, 12, 0],
  ['ма', 1993, 741, 933, 319],
  ['страль', 6, 0, 0, 6],
  ['мар', 121, 79, 37, 5],
  ['сло', 236, 72, 149, 15],
  ['слу', 96, 28, 68, 0],
  ['жба', 15, 0, 0, 15],
  ['стан', 61, 13, 45, 2],
  ['ха', 479, 82, 200, 197],
  ['мо', 1981, 518, 1448, 14],
  ['лизм', 149, 0, 0, 149],
  ['дель', 82, 11, 47, 24],
  ['тор', 713, 65, 130, 517],
  ['кла', 210, 54, 154, 2],
  ['дна', 30, 0, 29, 1],
  ['сец', 75, 0, 0, 75],
  ['пры', 32, 12, 20, 0],
  ['ски', 115, 20, 94, 1],
  ['пы', 161, 37, 124, 0],
  ['тде', 39, 0, 39, 0],
  ['ле', 1892, 308, 1562, 22],
  ['лень', 176, 0, 167, 8],
  ['тряд', 10, 0, 0, 10],
  ['жир', 21, 7, 4, 9],
  ['пе', 1654, 1216, 436, 2],
  ['ре', 2471, 690, 1769, 11],
  ['во', 1685, 580, 1064, 41],
  ['зчик', 72, 0, 0, 72],
  ['пер', 340, 160, 154, 26],
  ['нал', 61, 4, 10, 47],
  ['пись', 39, 12, 2, 25],
  ['пне', 44, 37, 7, 0],
  ['вма', 20, 1, 16, 3],
  ['тик', 158, 0, 0, 157],
  ['мка', 7, 0, 0, 7],
  ['чта', 14, 0, 4, 10],
  ['пре', 684, 478, 206, 0],
  ['дпри', 27, 0, 27, 0],
  ['ять', 39, 0, 33, 5],
  ['при', 1039, 833, 204, 2],
  ['про', 1761, 1226, 535, 0],
  ['мы', 227, 36, 190, 1],
  ['шле', 51, 3, 48, 0],
  ['нность', 849, 0, 0, 849],
  ['пу', 419, 222, 194, 3],
  ['мет', 6, 0, 0, 6],
  ['ше', 485, 105, 372, 8],
  ['стви', 46, 0, 46, 0],
  ['шка', 463, 9, 2, 452],
  ['дка', 281, 0, 19, 262],
  ['лон', 75, 10, 30, 35],
  ['сев', 11, 0, 0, 10],
  ['бще', 34, 0, 34, 0],
  ['спорт', 18, 0, 0, 17],
  ['сре', 61, 35, 26, 0],
  ['дство', 139, 0, 5, 134],
  ['ень', 73, 0, 73, 0],
  ['су', 598, 452, 145, 0],
  ['дно', 116, 3, 108, 4],
  ['тест', 8, 4, 0, 3],
  ['хник', 22, 0, 0, 22],
  ['хни', 57, 0, 57, 0],
  ['кум', 17, 2, 4, 10],
  ['пли', 89, 13, 76, 0],
  ['тран', 139, 98, 39, 2],
  ['тра', 372, 113, 238, 21],
  ['у', 2125, 1267, 844, 14],
  ['зел', 11, 1, 2, 8],
  ['фонд', 9, 0, 0, 8],
  ['часть', 8, 0, 0, 7],
  ['шко', 98, 7, 26, 65],
  ['ла', 944, 238, 485, 221],
  ['шо', 58, 33, 25, 0],
  ['э', 1511, 1180, 328, 3],
  ['кспорт', 7, 0, 0, 7],
  ['ска', 441, 71, 169, 201],
  ['дриль', 5, 0, 4, 1],
  ['зо', 938, 102, 828, 8],
  ['он', 165, 13, 31, 121],
  ['ми', 1556, 531, 1009, 15],
  ['ноз', 22, 0, 0, 22],
  ['вось', 21, 18, 3, 0],
  ['ври', 27, 0, 27, 0],
  ['ку', 628, 281, 345, 2],
  ['вро', 63, 1, 59, 3],
  ['встра', 7, 1, 6, 0],
  ['лий', 32, 0, 10, 22],
  ['ид', 115, 0, 0, 115],
  ['пи', 1049, 360, 683, 5],
  ['тек', 6, 0, 0, 6],
  ['як', 36, 0, 0, 35],
  ['ки', 741, 381, 349, 11],
  ['вто', 334, 13, 320, 1],
  ['ар', 273, 220, 30, 22],
  ['таль', 70, 8, 50, 11],
  ['стер', 132, 18, 43, 70],
  ['воз', 45, 0, 0, 44],
  ['шал', 21, 6, 11, 4],
  ['сме', 93, 46, 47, 0],
  ['граф', 160, 0, 0, 159],
  ['гра', 642, 137, 494, 11],
  ['физм', 42, 0, 0, 42],
  ['фи', 899, 234, 664, 1],
  ['чность', 360, 0, 0, 360],
  ['бло', 67, 27, 34, 6],
  ['бус', 14, 0, 0, 14],
  ['сник', 57, 0, 0, 57],
  ['гон', 112, 18, 47, 46],
  ['вла', 106, 44, 62, 0],
  ['лец', 108, 0, 0, 108],
  ['вор', 83, 32, 31, 19],
  ['вни', 80, 3, 77, 0],
  ['раж', 13, 0, 0, 12],
  ['ге', 566, 283, 281, 2],
  ['нез', 67, 0, 0, 67],
  ['дро', 300, 34, 259, 7],
  ['чи', 895, 98, 796, 1],
  ['вю', 12, 0, 11, 1],
  ['грей', 13, 4, 9, 0],
  ['дер', 286, 51, 167, 68],
  ['гу', 280, 115, 161, 4],
  ['дин', 77, 1, 35, 41],
  ['жник', 93, 0, 0, 93],
  ['дре', 97, 39, 57, 1],
  ['дром', 17, 1, 0, 15],
  ['жи', 701, 132, 568, 1],
  ['збра', 19, 0, 19, 0],
  ['сы', 243, 46, 197, 0],
  ['дец', 102, 0, 0, 102],
  ['пра', 336, 142, 192, 2],
  ['вщик', 272, 0, 0, 272],
  ['фе', 321, 116, 199, 6],
  ['фор', 226, 97, 110, 19],
  ['кар', 289, 166, 112, 11],
  ['лиз', 46, 0, 0, 46],
  ['клуб', 8, 0, 0, 7],
  ['код', 8, 1, 0, 6],
  ['бань', 32, 2, 30, 0],
  ['лли', 140, 0, 134, 6],
  ['нна', 54, 0, 27, 27],
  ['нат', 69, 0, 0, 69],
  ['пен', 101, 52, 47, 2],
  ['ссор', 36, 0, 10, 26],
  ['такт', 6, 0, 0, 5],
  ['кор', 179, 117, 49, 13],
  ['рре', 44, 0, 44, 0],
  ['мма', 149, 0, 30, 119],
  ['ррел', 6, 0, 6, 0],
  ['кран', 13, 0, 0, 12],
  ['кра', 242, 120, 118, 4],
  ['крат', 18, 0, 0, 17],
  ['кросс', 7, 2, 0, 4],
  ['кру', 142, 71, 71, 0],
  ['жок', 38, 0, 0, 38],
  ['хня', 5, 0, 0, 5],
  ['стни', 39, 0, 39, 0],
  ['ца', 1651, 29, 59, 1563],
  ['че', 939, 189, 748, 2],
  ['гни', 72, 9, 63, 0],
  ['фон', 86, 13, 5, 67],
  ['мат', 39, 0, 1, 37],
  ['тчик', 160, 0, 1, 159],
  ['тчи', 74, 0, 74, 0],
  ['ши', 515, 94, 419, 2],
  ['дте', 7, 0, 7, 0],
  ['ды', 164, 23, 138, 3],
  ['згру', 8, 0, 8, 0],
  ['биль', 34, 5, 20, 9],
  ['се', 727, 392, 333, 2],
  ['ксу', 20, 0, 20, 0],
  ['мор', 153, 60, 78, 14],
  ['три', 490, 172, 313, 5],
  ['ним', 26, 5, 5, 16],
  ['вость', 305, 0, 0, 305],
  ['мист', 38, 0, 0, 38],
  ['ном', 22, 0, 10, 11],
  ['бслу', 15, 0, 15, 0],
  ['тве', 50, 5, 45, 0],
  ['виль', 28, 4, 21, 3],
  ['пан', 118, 55, 15, 47],
  ['парк', 10, 0, 0, 9],
  ['ход', 43, 0, 0, 42],
  ['лот', 27, 0, 1, 25],
  ['пла', 335, 186, 149, 0],
  ['втор', 13, 3, 6, 4],
  ['ча', 506, 96, 316, 94],
  ['дстрой', 5, 0, 3, 2],
  ['подъ', 37, 12, 25, 0],
  ['езд', 24, 0, 0, 24],
  ['ил', 7, 0, 3, 3],
  ['мер', 234, 49, 74, 111],
  ['пор', 169, 75, 77, 17],
  ['цеп', 7, 0, 0, 6],
  ['бег', 12, 0, 0, 11],
  ['мми', 45, 0, 41, 4],
  ['зво', 100, 6, 94, 0],
  ['дчик', 92, 0, 0, 92],
  ['гул', 57, 10, 38, 8],
  ['зю', 7, 2, 5, 0],
  ['монт', 9, 0, 0, 9],
  ['мон', 106, 31, 50, 25],
  ['тник', 178, 0, 0, 178],
  ['рат', 73, 0, 0, 73],
  ['фри', 56, 29, 27, 0],
  ['тет', 38, 0, 0, 38],
  ['ру', 460, 189, 268, 3],
  ['вой', 62, 4, 7, 50],
  ['сбор', 26, 6, 15, 4],
  ['свар', 14, 6, 8, 0],
  ['свет', 8, 0, 0, 7],
  ['сле', 146, 39, 107, 0],
  ['сарь', 8, 0, 0, 8],
  ['жде', 147, 0, 147, 0],
  ['ждень', 113, 0, 113, 0],
  ['спуск', 11, 0, 0, 10],
  ['сте', 418, 184, 234, 0],
  ['кло', 160, 21, 131, 7],
  ['стоп', 7, 4, 0, 3],
  ['сто', 589, 90, 493, 6],
  ['пщик', 26, 0, 0, 26],
  ['ян', 124, 5, 102, 17],
  ['стра', 198, 67, 116, 15],
  ['пка', 122, 0, 1, 121],
  ['тай', 57, 26, 20, 11],
  ['жка', 202, 0, 0, 202],
  ['вле', 220, 4, 216, 0],
  ['влень', 148, 0, 148, 0],
  ['тип', 24, 0, 0, 23],
  ['моз', 12, 0, 0, 12],
  ['сфор', 24, 2, 21, 1],
  ['троф', 6, 0, 0, 6],
  ['фильтр', 14, 0, 0, 13],
  ['фре', 25, 17, 7, 1],
  ['фур', 16, 12, 3, 1],
  ['хо', 712, 151, 553, 8],
  ['зяй', 17, 0, 17, 0],
  ['хром', 15, 1, 7, 6],
  ['це', 486, 118, 304, 64],
  ['центр', 19, 0, 0, 18],
  ['цех', 5, 0, 0, 4],
  ['штур', 12, 9, 3, 0],
  ['ман', 231, 53, 122, 56],
  ['ктрик', 7, 0, 0, 7],
  ['ктро', 341, 0, 341, 0],
  ['вуль', 14, 9, 5, 0],
  ['хан', 33, 9, 14, 9],
  ['гал', 37, 13, 14, 10],
  ['галь', 47, 27, 17, 3],
  ['нин', 309, 0, 19, 290],
  ['фит', 35, 0, 0, 35],
  ['генс', 6, 0, 1, 5],
  ['гент', 16, 0, 0, 16],
  ['ша', 417, 144, 88, 185],
  ['тпо', 8, 0, 8, 0],
  ['гли', 76, 39, 35, 2],
  ['гло', 121, 28, 92, 1],
  ['тон', 149, 20, 29, 99],
  ['гнат', 6, 0, 0, 6],
  ['гна', 31, 1, 29, 1],
  ['гно', 58, 20, 37, 1],
  ['стик', 47, 0, 0, 47],
  ['цизм', 34, 0, 0, 34],
  ['фо', 636, 315, 321, 0],
  ['мант', 10, 0, 0, 10],
  ['гри', 50, 31, 19, 0],
  ['куль', 59, 31, 21, 6],
  ['гро', 133, 38, 95, 0],
  ['знес', 16, 0, 11, 5],
  ['зне', 57, 0, 57, 0],
  ['смен', 12, 4, 3, 5],
  ['хи', 352, 70, 281, 1],
  ['мик', 31, 0, 0, 31],
  ['бир', 13, 5, 7, 1],
  ['ду', 472, 157, 311, 4],
  ['стри', 85, 21, 64, 0],
  ['ринг', 18, 0, 1, 16],
  ['плекс', 15, 0, 3, 12],
  ['вер', 355, 113, 213, 29],
  ['цей', 12, 5, 3, 4],
  ['тинг', 22, 0, 0, 21],
  ['мум', 12, 2, 0, 10],
  ['чво', 12, 0, 12, 0],
  ['ем', 57, 1, 49, 7],
  ['пункт', 8, 0, 0, 7],
  ['сер', 158, 96, 50, 12],
  ['вис', 9, 0, 1, 7],
  ['сеть', 9, 0, 0, 8],
  ['строй', 55, 8, 42, 4],
  ['хно', 45, 0, 45, 0],
  ['фир', 28, 4, 8, 16],
  ['кат', 47, 0, 0, 46],
  ['лин', 136, 21, 45, 70],
  ['мит', 29, 0, 0, 29],
  ['дам', 13, 5, 4, 4],
  ['сит', 10, 0, 0, 10],
  ['пта', 27, 3, 20, 4],
  ['птер', 13, 0, 0, 13],
  ['пти', 71, 14, 57, 0],
  ['мость', 380, 0, 0, 380],
  ['пто', 50, 1, 49, 0],
  ['дат', 12, 0, 0, 12],
  ['две', 30, 3, 27, 0],
  ['двер', 12, 2, 8, 2],
  ['аль', 140, 81, 54, 5],
  ['дво', 78, 37, 41, 0],
  ['дум', 25, 2, 11, 12],
  ['дди', 7, 0, 5, 2],
  ['бец', 35, 0, 0, 35],
  ['бка', 86, 0, 8, 78],
  ['дду', 9, 0, 9, 0],
  ['ква', 122, 61, 50, 10],
  ['фан', 48, 23, 9, 16],
  ['нит', 123, 0, 0, 122],
  ['рус', 39, 0, 1, 38],
  ['физ', 9, 0, 0, 9],
  ['сфат', 16, 0, 0, 16],
  ['мин', 94, 18, 19, 57],
  ['джи', 38, 9, 27, 1],
  ['ан', 448, 327, 95, 26],
  ['тум', 17, 3, 3, 11],
  ['дми', 23, 3, 20, 0],
  ['рал', 41, 0, 24, 17],
  ['лле', 65, 0, 64, 1],
  ['тей', 38, 1, 32, 5],
  ['раль', 32, 0, 23, 9],
  ['зид', 6, 0, 0, 6],
  ['лен', 125, 14, 86, 24],
  ['нис', 6, 0, 0, 6],
  ['лэ', 8, 1, 5, 1],
  ['кто', 165, 0, 164, 1],
  ['дарь', 9, 0, 0, 9],
  ['сность', 71, 0, 0, 71],
  ['сок', 48, 0, 0, 47],
  ['чек', 160, 0, 0, 159],
  ['дрон', 14, 0, 0, 14],
  ['дсор', 5, 0, 5, 0],
  ['бат', 15, 0, 0, 14],
  ['дул', 18, 1, 16, 1],
  ['яр', 75, 7, 42, 25],
  ['адъ', 13, 9, 4, 0],
  ['ктив', 16, 0, 0, 16],
  ['юн', 25, 8, 16, 1],
  ['кту', 66, 0, 66, 0],
  ['тант', 23, 0, 0, 23],
  ['тан', 159, 38, 52, 68],
  ['гей', 12, 6, 3, 3],
  ['тер', 394, 129, 189, 76],
  ['жу', 45, 21, 20, 4],
  ['аз', 15, 0, 0, 14],
  ['зан', 48, 6, 29, 13],
  ['зар', 39, 13, 21, 5],
  ['збу', 25, 0, 25, 0],
  ['вник', 107, 0, 0, 107],
  ['зе', 154, 30, 121, 3],
  ['троп', 16, 0, 0, 15],
  ['зер', 102, 41, 36, 25],
  ['бай', 34, 16, 14, 3],
  ['вщи', 131, 0, 131, 0],
  ['ат', 27, 0, 0, 27],
  ['мут', 7, 0, 0, 7],
  ['мид', 18, 0, 0, 18],
  ['зол', 49, 1, 17, 31],
  ['вец', 191, 0, 0, 191],
  ['вча', 47, 0, 47, 0],
  ['вчан', 43, 0, 43, 0],
  ['спер', 22, 9, 13, 0],
  ['ктер', 9, 0, 5, 4],
  ['кте', 35, 0, 34, 1],
  ['кса', 57, 0, 47, 10],
  ['зу', 187, 51, 133, 3],
  ['зур', 19, 3, 15, 1],
  ['рит', 78, 0, 0, 78],
  ['ир', 21, 12, 5, 3],
  ['стё', 12, 4, 8, 0],
  ['нок', 212, 1, 0, 210],
  ['стник', 49, 0, 0, 49],
  ['ай', 18, 16, 2, 0],
  ['кань', 138, 1, 137, 0],
  ['мак', 12, 0, 0, 11],
  ['йо', 37, 13, 23, 0],
  ['ран', 109, 11, 71, 27],
  ['рол', 15, 1, 3, 10],
  ['синг', 7, 0, 0, 7],
  ['сор', 66, 36, 18, 11],
  ['мизм', 30, 0, 0, 30],
  ['кант', 13, 0, 0, 12],
  ['цид', 27, 0, 0, 27],
  ['фист', 17, 0, 0, 17],
  ['вит', 11, 0, 0, 11],
  ['лан', 145, 35, 91, 18],
  ['гист', 11, 0, 0, 11],
  ['навт', 8, 0, 0, 8],
  ['вти', 16, 3, 13, 0],
  ['план', 50, 16, 9, 24],
  ['воль', 94, 46, 48, 0],
  ['фра', 76, 36, 36, 3],
  ['нень', 151, 0, 151, 0],
  ['рель', 32, 6, 12, 13],
  ['ум', 106, 20, 32, 53],
  ['кве', 15, 7, 8, 0],
  ['дук', 9, 0, 0, 8],
  ['кви', 53, 10, 43, 0],
  ['кка', 13, 0, 10, 3],
  ['кко', 12, 1, 8, 3],
  ['кком', 5, 0, 5, 0],
  ['ккре', 6, 0, 6, 0],
  ['кку', 20, 0, 20, 0],
  ['мул', 31, 3, 25, 2],
  ['кме', 6, 0, 6, 0],
  ['кри', 167, 110, 56, 1],
  ['трид', 5, 0, 0, 5],
  ['хин', 20, 3, 9, 8],
  ['кро', 477, 114, 363, 0],
  ['крон', 19, 10, 0, 8],
  ['поль', 47, 7, 31, 9],
  ['стих', 7, 0, 0, 6],
  ['фут', 5, 1, 0, 3],
  ['кал', 38, 8, 14, 15],
  ['ксе', 45, 27, 18, 0],
  ['рант', 24, 0, 0, 23],
  ['ксель', 6, 0, 1, 5],
  ['ссу', 36, 8, 28, 0],
  ['кси', 165, 13, 151, 1],
  ['ксо', 51, 0, 51, 0],
  ['ксон', 8, 0, 3, 5],
  ['ктёр', 8, 0, 4, 4],
  ['ктант', 9, 0, 0, 9],
  ['кта', 43, 0, 40, 3],
  ['вин', 68, 19, 41, 8],
  ['вист', 24, 0, 0, 23],
  ['ктин', 5, 0, 0, 5],
  ['нид', 14, 0, 0, 14],
  ['ний', 52, 0, 9, 43],
  ['коз', 14, 0, 0, 14],
  ['нон', 27, 6, 4, 17],
  ['ктри', 29, 0, 29, 0],
  ['ус', 29, 0, 1, 27],
  ['пун', 37, 11, 11, 15],
  ['кут', 6, 0, 0, 5],
  ['шер', 34, 26, 2, 6],
  ['кце', 12, 0, 12, 0],
  ['кцен', 5, 0, 5, 0],
  ['птор', 15, 0, 1, 14],
  ['пци', 15, 0, 15, 0],
  ['дент', 24, 0, 1, 23],
  ['ден', 90, 23, 63, 4],
  ['зный', 6, 0, 0, 6],
  ['ал', 135, 62, 10, 63],
  ['ён', 10, 0, 10, 0],
  ['мец', 53, 0, 0, 53],
  ['лар', 14, 4, 6, 4],
  ['зис', 43, 0, 0, 43],
  ['гол', 27, 6, 11, 9],
  ['кин', 20, 6, 7, 7],
  ['тми', 15, 0, 15, 0],
  ['бар', 98, 58, 30, 9],
  ['врит', 6, 0, 0, 6],
  ['лей', 62, 24, 25, 12],
  ['рон', 63, 5, 27, 31],
  ['ксан', 10, 2, 5, 3],
  ['дри', 58, 4, 53, 1],
  ['дрит', 9, 0, 0, 9],
  ['ксин', 24, 0, 0, 24],
  ['тщик', 6, 0, 0, 6],
  ['лоз', 5, 0, 0, 5],
  ['кил', 5, 2, 1, 1],
  ['лик', 46, 0, 0, 45],
  ['голь', 52, 5, 40, 6],
  ['ят', 7, 0, 1, 6],
  ['ллан', 6, 0, 4, 2],
  ['лла', 83, 0, 30, 53],
  ['тто', 17, 0, 9, 8],
  ['ллей', 7, 0, 5, 2],
  ['морф', 8, 0, 0, 7],
  ['ллер', 27, 0, 9, 18],
  ['гик', 5, 0, 0, 4],
  ['тоз', 25, 0, 0, 25],
  ['луй', 7, 0, 4, 3],
  ['щи', 440, 19, 421, 0],
  ['цин', 41, 7, 10, 24],
  ['лло', 118, 0, 117, 1],
  ['пат', 13, 0, 0, 12],
  ['зма', 67, 0, 32, 35],
  ['пло', 262, 71, 187, 4],
  ['скоп', 108, 0, 0, 107],
  ['сплан', 13, 2, 10, 1],
  ['тат', 23, 0, 0, 22],
  ['тро', 409, 81, 323, 5],
  ['ллю', 34, 0, 34, 0],
  ['вий', 13, 0, 4, 9],
  ['маз', 5, 0, 0, 4],
  ['бы', 131, 30, 101, 0],
  ['гизм', 19, 0, 0, 19],
  ['ит', 23, 0, 0, 23],
  ['тарь', 16, 0, 0, 16],
  ['тын', 5, 1, 1, 2],
  ['ты', 267, 23, 242, 2],
  ['нник', 228, 0, 0, 228],
  ['лу', 450, 91, 358, 1],
  ['лы', 121, 15, 106, 0],
  ['нос', 44, 0, 1, 42],
  ['бит', 21, 0, 0, 20],
  ['ну', 192, 50, 142, 0],
  ['гва', 13, 7, 4, 2],
  ['зил', 8, 0, 4, 4],
  ['сил', 14, 1, 10, 3],
  ['гин', 23, 0, 10, 13],
  ['гид', 10, 0, 0, 9],
  ['сар', 46, 30, 10, 6],
  ['нах', 6, 0, 0, 6],
  ['шник', 80, 0, 0, 80],
  ['герь', 7, 0, 0, 7],
  ['нант', 12, 0, 0, 12],
  ['тру', 116, 75, 41, 0],
  ['тил', 61, 0, 50, 11],
  ['фин', 28, 11, 7, 10],
  ['трон', 66, 0, 5, 60],
  ['янс', 5, 0, 0, 5],
  ['гель', 36, 7, 14, 14],
  ['тость', 181, 0, 0, 181],
  ['ам', 96, 80, 14, 2],
  ['цит', 34, 0, 0, 34],
  ['вроз', 7, 0, 0, 7],
  ['тян', 22, 0, 22, 0],
  ['маль', 59, 24, 25, 10],
  ['тёр', 47, 2, 16, 29],
  ['бал', 39, 17, 16, 5],
  ['лаж', 5, 0, 0, 4],
  ['верт', 10, 0, 0, 10],
  ['бли', 143, 15, 127, 1],
  ['бро', 193, 52, 138, 3],
  ['вон', 5, 0, 2, 3],
  ['фил', 34, 4, 2, 28],
  ['фоб', 15, 0, 0, 15],
  ['ций', 20, 0, 2, 18],
  ['ант', 21, 0, 0, 20],
  ['гда', 5, 0, 5, 0],
  ['дол', 75, 37, 24, 13],
  ['шон', 14, 0, 6, 8],
  ['мил', 17, 5, 8, 4],
  ['доз', 5, 0, 0, 5],
  ['донт', 8, 0, 0, 8],
  ['пласт', 17, 0, 0, 16],
  ['спирт', 5, 0, 0, 4],
  ['нол', 22, 0, 4, 18],
  ['хло', 115, 61, 52, 2],
  ['рид', 30, 0, 0, 30],
  ['рис', 7, 0, 0, 6],
  ['ак', 8, 0, 0, 8],
  ['хра', 87, 19, 66, 2],
  ['ммо', 23, 0, 23, 0],
  ['фос', 9, 0, 0, 9],
  ['ско', 294, 112, 175, 7],
  ['от', 15, 0, 0, 15],
  ['нный', 34, 0, 0, 34],
  ['мок', 35, 0, 0, 35],
  ['тиль', 46, 2, 35, 9],
  ['час', 7, 0, 0, 6],
  ['тва', 25, 1, 10, 14],
  ['пир', 13, 6, 1, 5],
  ['ллин', 16, 0, 6, 10],
  ['плу', 22, 19, 3, 0],
  ['пул', 19, 2, 14, 2],
  ['пуль', 40, 28, 12, 0],
  ['мур', 19, 14, 1, 4],
  ['бол', 54, 24, 16, 13],
  ['ксис', 21, 0, 0, 21],
  ['под', 10, 0, 0, 10],
  ['стиль', 30, 3, 12, 14],
  ['сбе', 17, 4, 13, 0],
  ['атр', 14, 0, 0, 14],
  ['бас', 7, 0, 0, 6],
  ['сис', 8, 0, 0, 8],
  ['оз', 34, 0, 0, 33],
  ['глиф', 7, 0, 0, 6],
  ['кард', 5, 0, 0, 5],
  ['кре', 162, 79, 83, 0],
  ['птик', 11, 0, 0, 11],
  ['лой', 5, 0, 1, 4],
  ['наль', 58, 0, 44, 14],
  ['цим', 7, 4, 1, 2],
  ['нам', 15, 3, 12, 0],
  ['фоз', 5, 0, 0, 5],
  ['нан', 28, 3, 23, 2],
  ['пча', 12, 0, 12, 0],
  ['нар', 90, 36, 36, 18],
  ['хизм', 9, 0, 0, 9],
  ['хист', 6, 0, 0, 6],
  ['гма', 34, 0, 23, 11],
  ['том', 53, 11, 27, 14],
  ['рез', 45, 0, 0, 44],
  ['рет', 15, 0, 0, 15],
  ['хро', 139, 65, 74, 0],
  ['роб', 8, 0, 0, 8],
  ['лок', 65, 0, 0, 65],
  ['дрид', 5, 0, 0, 5],
  ['тен', 98, 20, 72, 6],
  ['гле', 57, 4, 53, 0],
  ['зит', 43, 0, 0, 43],
  ['чан', 71, 0, 68, 2],
  ['нор', 54, 24, 18, 12],
  ['вед', 50, 0, 0, 50],
  ['филь', 41, 23, 16, 2],
  ['бство', 17, 0, 3, 14],
  ['сту', 154, 30, 124, 0],
  ['унд', 6, 0, 1, 5],
  ['жан', 33, 4, 27, 2],
  ['кли', 119, 50, 68, 1],
  ['фаг', 13, 0, 0, 12],
  ['кдо', 5, 0, 5, 0],
  ['тец', 41, 0, 0, 41],
  ['рум', 17, 5, 5, 7],
  ['хор', 17, 8, 5, 3],
  ['зист', 12, 0, 0, 12],
  ['тол', 64, 45, 13, 5],
  ['пность', 24, 0, 0, 24],
  ['кас', 7, 0, 0, 7],
  ['кер', 77, 12, 7, 58],
  ['рок', 70, 4, 0, 65],
  ['завр', 24, 0, 0, 24],
  ['нне', 18, 0, 18, 0],
  ['нни', 117, 0, 115, 2],
  ['гил', 7, 2, 4, 1],
  ['нно', 48, 0, 47, 1],
  ['сцен', 41, 1, 40, 0],
  ['рак', 15, 0, 0, 14],
  ['тит', 41, 0, 0, 40],
  ['сми', 30, 14, 16, 0],
  ['бле', 121, 14, 107, 0],
  ['твер', 59, 14, 45, 0],
  ['дий', 49, 0, 15, 34],
  ['фикс', 10, 0, 0, 9],
  ['дар', 68, 14, 39, 14],
  ['дей', 64, 12, 38, 14],
  ['дем', 15, 6, 4, 4],
  ['пинг', 9, 1, 0, 8],
  ['ссант', 6, 0, 0, 6],
  ['дот', 5, 1, 0, 3],
  ['им', 83, 45, 37, 1],
  ['ску', 104, 33, 71, 0],
  ['сство', 13, 0, 5, 8],
  ['тод', 9, 0, 0, 9],
  ['квар', 36, 30, 5, 1],
  ['кле', 113, 51, 61, 1],
  ['макс', 5, 0, 0, 5],
  ['янт', 12, 0, 0, 12],
  ['мму', 52, 0, 52, 0],
  ['мир', 23, 9, 4, 9],
  ['ссон', 6, 0, 1, 5],
  ['пин', 41, 12, 12, 16],
  ['ней', 70, 49, 20, 1],
  ['клон', 18, 0, 7, 10],
  ['сли', 98, 29, 68, 1],
  ['дант', 8, 0, 0, 8],
  ['пист', 12, 0, 0, 12],
  ['грамм', 18, 6, 2, 9],
  ['ссат', 5, 0, 0, 5],
  ['пол', 237, 143, 86, 7],
  ['сталь', 15, 2, 10, 2],
  ['сизм', 5, 0, 0, 5],
  ['зник', 43, 0, 0, 43],
  ['фле', 39, 18, 20, 1],
  ['гне', 81, 11, 70, 0],
  ['мме', 27, 0, 27, 0],
  ['впа', 16, 9, 7, 0],
  ['день', 187, 1, 169, 16],
  ['шист', 11, 0, 0, 11],
  ['фер', 60, 22, 21, 17],
  ['рро', 51, 0, 50, 1],
  ['фун', 33, 20, 12, 1],
  ['хлор', 49, 13, 32, 3],
  ['чник', 256, 0, 0, 256],
  ['ктрон', 12, 0, 4, 8],
  ['ллит', 16, 0, 0, 16],
  ['цен', 125, 41, 71, 13],
  ['тре', 203, 101, 102, 0],
  ['кот', 13, 0, 0, 12],
  ['прен', 8, 0, 2, 6],
  ['соль', 25, 9, 6, 9],
  ['пси', 112, 78, 33, 1],
  ['соф', 5, 0, 0, 5],
  ['фу', 99, 70, 29, 0],
  ['тризм', 9, 0, 0, 8],
  ['тье', 7, 0, 2, 5],
  ['нус', 16, 0, 0, 15],
  ['ху', 63, 36, 25, 2],
  ['чар', 17, 7, 6, 4],
  ['чо', 48, 9, 33, 6],
  ['шлаг', 5, 0, 0, 4],
  ['нэ', 13, 2, 11, 0],
  ['ор', 204, 152, 44, 8],
  ['пар', 137, 100, 34, 2],
  ['ллинг', 7, 0, 0, 7],
  ['лля', 22, 0, 22, 0],
  ['пель', 32, 5, 13, 14],
  ['каль', 70, 28, 38, 4],
  ['ный', 34, 0, 0, 34],
  ['псис', 11, 0, 0, 11],
  ['лид', 5, 0, 0, 5],
  ['гет', 6, 0, 0, 6],
  ['пле', 243, 76, 167, 0],
  ['спо', 204, 34, 170, 0],
  ['стат', 26, 0, 0, 26],
  ['стол', 30, 15, 12, 2],
  ['столь', 8, 1, 5, 2],
  ['тна', 17, 0, 17, 0],
  ['ппен', 5, 0, 5, 0],
  ['дэ', 9, 0, 8, 1],
  ['ппер', 11, 0, 3, 8],
  ['ппе', 5, 0, 5, 0],
  ['ппо', 21, 0, 21, 0],
  ['ппре', 5, 0, 5, 0],
  ['тур', 112, 57, 51, 3],
  ['ппро', 5, 0, 5, 0],
  ['прель', 6, 4, 0, 1],
  ['пте', 21, 8, 13, 0],
  ['карь', 21, 8, 0, 13],
  ['бизм', 8, 0, 0, 8],
  ['бист', 9, 0, 0, 9],
  ['тя', 138, 37, 94, 7],
  ['мей', 26, 1, 24, 1],
  ['пник', 25, 0, 0, 25],
  ['дит', 25, 0, 0, 25],
  ['траж', 6, 0, 0, 6],
  ['жер', 35, 18, 8, 9],
  ['зик', 28, 0, 1, 27],
  ['вя', 112, 23, 89, 0],
  ['гус', 8, 0, 0, 8],
  ['рен', 112, 28, 74, 9],
  ['ель', 8, 4, 1, 2],
  ['тта', 42, 0, 15, 27],
  ['стар', 31, 18, 13, 0],
  ['стон', 16, 0, 5, 10],
  ['фмо', 5, 0, 5, 0],
  ['зир', 5, 0, 2, 3],
  ['вир', 18, 10, 2, 6],
  ['дил', 7, 1, 3, 3],
  ['слинг', 6, 0, 0, 5],
  ['мя', 47, 38, 9, 0],
  ['чок', 132, 0, 0, 132],
  ['нет', 32, 0, 7, 25],
  ['тир', 33, 2, 15, 15],
  ['скле', 30, 20, 10, 0],
  ['роз', 18, 0, 0, 18],
  ['кул', 52, 4, 39, 9],
  ['шок', 37, 0, 0, 36],
  ['тос', 6, 0, 0, 6],
  ['дго', 23, 0, 23, 0],
  ['трал', 9, 2, 3, 3],
  ['траль', 19, 1, 9, 9],
  ['трит', 12, 0, 0, 12],
  ['хар', 39, 19, 17, 3],
  ['хат', 8, 0, 1, 7],
  ['хе', 69, 31, 38, 0],
  ['хив', 5, 0, 1, 4],
  ['вольт', 12, 1, 1, 9],
  ['глу', 54, 24, 29, 1],
  ['пость', 10, 0, 0, 10],
  ['пство', 9, 0, 2, 7],
  ['рей', 57, 21, 18, 17],
  ['стор', 41, 6, 18, 17],
  ['стырь', 6, 0, 0, 6],
  ['лаг', 5, 0, 0, 4],
  ['чак', 15, 0, 0, 15],
  ['чин', 28, 2, 22, 3],
  ['шин', 32, 9, 19, 4],
  ['ер', 69, 5, 18, 45],
  ['ас', 7, 0, 0, 6],
  ['кно', 44, 2, 27, 15],
  ['стит', 10, 0, 0, 10],
  ['стоз', 6, 0, 0, 6],
  ['сбо', 10, 2, 8, 0],
  ['ткань', 6, 1, 0, 4],
  ['сим', 34, 30, 4, 0],
  ['ске', 22, 10, 12, 0],
  ['скер', 7, 2, 1, 4],
  ['спа', 80, 27, 52, 1],
  ['спар', 17, 7, 8, 1],
  ['кам', 44, 40, 3, 1],
  ['спи', 115, 49, 66, 0],
  ['гай', 23, 11, 6, 5],
  ['жист', 16, 0, 0, 16],
  ['ссем', 9, 0, 9, 0],
  ['блер', 5, 0, 0, 5],
  ['ссе', 70, 2, 63, 5],
  ['бил', 19, 0, 16, 3],
  ['вска', 5, 4, 1, 0],
  ['стен', 19, 5, 11, 2],
  ['ссо', 59, 1, 56, 2],
  ['нанс', 6, 0, 0, 6],
  ['сфе', 62, 11, 51, 0],
  ['стма', 5, 0, 4, 1],
  ['люк', 6, 0, 0, 5],
  ['строл', 5, 0, 4, 1],
  ['строн', 7, 2, 0, 5],
  ['сун', 16, 3, 7, 6],
  ['сфаль', 12, 0, 12, 0],
  ['сфи', 9, 5, 4, 0],
  ['сфо', 15, 0, 15, 0],
  ['сци', 20, 3, 17, 0],
  ['визм', 27, 0, 0, 27],
  ['ксит', 7, 0, 0, 7],
  ['лол', 7, 0, 3, 4],
  ['тлан', 13, 0, 13, 0],
  ['тле', 35, 3, 32, 0],
  ['тмо', 26, 0, 26, 0],
  ['стой', 65, 6, 48, 11],
  ['бут', 5, 0, 0, 4],
  ['тте', 16, 0, 16, 0],
  ['тти', 16, 0, 11, 5],
  ['гмен', 10, 0, 8, 2],
  ['ул', 12, 1, 3, 8],
  ['халь', 20, 4, 14, 2],
  ['скуль', 6, 3, 3, 0],
  ['стем', 6, 1, 3, 1],
  ['динг', 11, 0, 1, 10],
  ['ткро', 7, 0, 7, 0],
  ['нинг', 9, 0, 1, 8],
  ['гер', 92, 44, 23, 25],
  ['фрит', 6, 0, 0, 6],
  ['фро', 45, 1, 43, 1],
  ['ффект', 7, 0, 0, 7],
  ['ффе', 23, 0, 23, 0],
  ['чер', 175, 115, 51, 9],
  ['ффи', 12, 0, 11, 1],
  ['наж', 12, 0, 0, 12],
  ['хань', 51, 0, 50, 1],
  ['хон', 17, 5, 9, 2],
  ['хтер', 6, 0, 3, 3],
  ['пик', 18, 0, 0, 17],
  ['ште', 6, 4, 2, 0],
  ['вень', 60, 0, 44, 16],
  ['шке', 5, 0, 5, 0],
  ['пру', 36, 20, 16, 0],
  ['гость', 16, 0, 0, 15],
  ['золь', 8, 2, 1, 4],
  ['лак', 9, 0, 0, 8],
  ['ктон', 8, 0, 0, 8],
  ['сни', 80, 12, 68, 0],
  ['сол', 49, 31, 6, 12],
  ['пизм', 18, 0, 0, 18],
  ['тосъ', 6, 0, 6, 0],
  ['кспресс', 12, 0, 10, 2],
  ['эм', 33, 28, 4, 0],
  ['юр', 7, 4, 1, 1],
  ['бёр', 6, 2, 1, 3],
  ['бён', 6, 0, 6, 0],
  ['бё', 17, 0, 17, 0],
  ['ща', 127, 2, 69, 56],
  ['бник', 31, 0, 0, 31],
  ['чни', 85, 0, 85, 0],
  ['бул', 18, 5, 11, 2],
  ['сень', 49, 6, 39, 3],
  ['ся', 100, 3, 85, 12],
  ['гам', 14, 8, 3, 2],
  ['уд', 5, 0, 0, 4],
  ['гор', 119, 82, 28, 9],
  ['гря', 31, 21, 10, 0],
  ['гуль', 13, 5, 7, 1],
  ['дан', 37, 4, 19, 14],
  ['вик', 141, 0, 0, 141],
  ['дак', 14, 0, 0, 14],
  ['рам', 17, 7, 5, 5],
  ['байт', 6, 1, 0, 4],
  ['кен', 25, 8, 8, 9],
  ['клу', 21, 14, 7, 0],
  ['псин', 5, 0, 1, 4],
  ['рад', 9, 0, 0, 9],
  ['ган', 96, 15, 51, 30],
  ['гур', 22, 8, 11, 3],
  ['ланс', 5, 0, 0, 5],
  ['бес', 6, 0, 0, 5],
  ['дник', 115, 0, 0, 115],
  ['тни', 81, 0, 79, 2],
  ['ллон', 10, 0, 3, 7],
  ['вство', 35, 0, 5, 30],
  ['лык', 5, 0, 0, 5],
  ['баль', 24, 14, 9, 1],
  ['зам', 22, 17, 2, 2],
  ['бук', 6, 0, 0, 5],
  ['ед', 41, 0, 0, 41],
  ['гла', 162, 73, 86, 3],
  ['шец', 5, 0, 0, 5],
  ['риль', 25, 0, 24, 1],
  ['роль', 16, 6, 4, 5],
  ['джо', 9, 5, 2, 2],
  ['банк', 14, 0, 0, 13],
  ['шни', 36, 1, 35, 0],
  ['кет', 31, 0, 0, 31],
  ['кир', 17, 12, 3, 2],
  ['ёт', 33, 0, 0, 33],
  ['ннер', 6, 0, 1, 5],
  ['буль', 11, 10, 1, 0],
  ['хли', 7, 1, 6, 0],
  ['хол', 24, 15, 5, 4],
  ['холь', 5, 0, 3, 2],
  ['хтань', 6, 0, 6, 0],
  ['шек', 28, 0, 0, 28],
  ['кю', 18, 13, 3, 2],
  ['рри', 23, 0, 19, 4],
  ['бет', 10, 0, 1, 9],
  ['тал', 36, 4, 21, 10],
  ['еф', 6, 0, 0, 6],
  ['ттер', 16, 0, 6, 10],
  ['сё', 19, 3, 16, 0],
  ['стве', 78, 0, 78, 0],
  ['стость', 131, 0, 0, 131],
  ['чук', 12, 0, 0, 12],
  ['шай', 11, 3, 6, 2],
  ['рынь', 5, 0, 3, 2],
  ['рын', 6, 1, 5, 0],
  ['рыш', 6, 0, 0, 6],
  ['ры', 163, 91, 71, 1],
  ['шня', 18, 1, 0, 17],
  ['барь', 7, 2, 0, 5],
  ['сен', 50, 32, 18, 0],
  ['тбо', 6, 0, 6, 0],
  ['скон', 22, 3, 18, 1],
  ['сма', 44, 24, 18, 2],
  ['сно', 128, 37, 90, 1],
  ['словь', 24, 0, 24, 0],
  ['сня', 6, 1, 2, 3],
  ['сон', 31, 6, 8, 16],
  ['щий', 39, 0, 5, 34],
  ['сур', 29, 19, 10, 0],
  ['лер', 32, 1, 9, 22],
  ['тень', 39, 1, 24, 13],
  ['тман', 9, 0, 4, 5],
  ['тун', 24, 3, 3, 17],
  ['тут', 8, 0, 0, 7],
  ['тыр', 24, 2, 20, 2],
  ['уль', 38, 28, 7, 3],
  ['фта', 16, 2, 7, 7],
  ['хвал', 5, 0, 2, 3],
  ['шен', 24, 2, 21, 1],
  ['шлы', 5, 2, 3, 0],
  ['шма', 9, 3, 4, 2],
  ['штан', 9, 7, 0, 2],
  ['гань', 53, 0, 51, 2],
  ['мот', 6, 0, 0, 5],
  ['глость', 5, 0, 0, 5],
  ['глян', 7, 3, 4, 0],
  ['тня', 46, 0, 6, 40],
  ['гун', 20, 1, 7, 12],
  ['гунь', 5, 1, 4, 0],
  ['эн', 95, 79, 14, 1],
  ['дня', 21, 0, 13, 8],
  ['дняк', 5, 0, 0, 5],
  ['кур', 75, 42, 24, 9],
  ['жен', 34, 3, 31, 0],
  ['заль', 26, 4, 21, 1],
  ['зби', 25, 0, 25, 0],
  ['збо', 8, 0, 8, 0],
  ['жни', 69, 4, 65, 0],
  ['стность', 63, 0, 0, 63],
  ['зви', 28, 0, 28, 0],
  ['стье', 54, 0, 0, 54],
  ['звра', 25, 0, 25, 0],
  ['зме', 80, 19, 61, 0],
  ['звре', 6, 0, 6, 0],
  ['мень', 44, 6, 29, 9],
  ['звы', 7, 0, 7, 0],
  ['згла', 20, 0, 20, 0],
  ['зго', 35, 0, 35, 0],
  ['згра', 8, 0, 8, 0],
  ['ждан', 7, 0, 7, 0],
  ['шность', 28, 0, 0, 28],
  ['здей', 5, 0, 5, 0],
  ['зде', 51, 0, 51, 0],
  ['здел', 8, 0, 3, 5],
  ['здель', 19, 0, 19, 0],
  ['чань', 59, 0, 58, 0],
  ['здо', 54, 8, 45, 1],
  ['жди', 11, 0, 11, 0],
  ['зду', 42, 0, 42, 0],
  ['зжа', 13, 0, 13, 0],
  ['зжи', 17, 0, 17, 0],
  ['зза', 14, 0, 14, 0],
  ['конь', 11, 4, 6, 0],
  ['ззе', 5, 0, 5, 0],
  ['мель', 25, 11, 7, 6],
  ['бность', 59, 0, 0, 59],
  ['бость', 7, 0, 0, 7],
  ['зли', 50, 0, 49, 1],
  ['зло', 91, 48, 40, 2],
  ['злу', 27, 0, 27, 0],
  ['злю', 5, 2, 3, 0],
  ['ток', 113, 1, 0, 111],
  ['змен', 9, 0, 6, 3],
  ['змер', 7, 0, 5, 2],
  ['змо', 42, 0, 42, 0],
  ['змол', 5, 0, 4, 1],
  ['змя', 5, 0, 5, 0],
  ['зна', 157, 27, 108, 22],
  ['дё', 23, 3, 20, 0],
  ['дзор', 8, 0, 2, 6],
  ['чаль', 31, 1, 29, 1],
  ['встве', 11, 0, 11, 0],
  ['зни', 43, 0, 42, 1],
  ['зность', 67, 0, 0, 67],
  ['бразь', 10, 0, 10, 0],
  ['гля', 29, 4, 24, 1],
  ['тла', 13, 0, 10, 3],
  ['тлу', 10, 0, 10, 0],
  ['тно', 53, 0, 51, 2],
  ['зра', 53, 2, 51, 0],
  ['тный', 9, 0, 0, 9],
  ['зре', 56, 4, 52, 0],
  ['зро', 5, 0, 5, 0],
  ['зру', 18, 0, 18, 0],
  ['зум', 15, 1, 13, 1],
  ['зумь', 5, 0, 5, 0],
  ['зы', 163, 7, 156, 0],
  ['зычь', 5, 0, 5, 0],
  ['схо', 54, 14, 40, 0],
  ['бей', 30, 14, 8, 7],
  ['сик', 23, 0, 0, 23],
  ['кке', 7, 0, 7, 0],
  ['кинг', 10, 0, 1, 8],
  ['бел', 24, 12, 8, 3],
  ['ёк', 62, 0, 0, 62],
  ['лем', 9, 2, 5, 2],
  ['лиль', 12, 0, 12, 0],
  ['ня', 158, 2, 14, 142],
  ['трист', 12, 0, 0, 12],
  ['гвар', 8, 2, 6, 0],
  ['зор', 52, 2, 25, 25],
  ['кровь', 9, 0, 7, 1],
  ['крыл', 8, 0, 6, 2],
  ['рость', 15, 0, 0, 15],
  ['ног', 5, 0, 0, 5],
  ['дкла', 6, 0, 6, 0],
  ['сне', 67, 41, 25, 1],
  ['хвост', 17, 0, 0, 16],
  ['швей', 11, 8, 3, 0],
  ['грант', 7, 0, 0, 6],
  ['гран', 31, 4, 25, 1],
  ['бель', 77, 19, 30, 27],
  ['гий', 10, 0, 3, 7],
  ['дю', 32, 14, 18, 0],
  ['цо', 89, 6, 61, 22],
  ['мес', 5, 0, 0, 5],
  ['флёр', 6, 2, 1, 2],
  ['моль', 12, 4, 5, 2],
  ['дрин', 6, 0, 1, 5],
  ['зель', 29, 1, 14, 14],
  ['сос', 22, 0, 0, 22],
  ['тстой', 7, 0, 6, 1],
  ['пом', 22, 15, 6, 1],
  ['там', 23, 16, 5, 2],
  ['бон', 20, 10, 4, 5],
  ['дыш', 10, 0, 0, 10],
  ['рег', 5, 0, 0, 5],
  ['ву', 56, 8, 47, 1],
  ['жли', 5, 0, 5, 0],
  ['зня', 10, 0, 2, 8],
  ['зняк', 6, 0, 0, 6],
  ['рем', 16, 4, 9, 3],
  ['стян', 7, 0, 7, 0],
  ['рил', 7, 0, 5, 1],
  ['ллий', 8, 0, 1, 7],
  ['саль', 34, 17, 14, 3],
  ['зыр', 11, 2, 9, 0],
  ['ском', 7, 1, 6, 0],
  ['троль', 16, 0, 4, 12],
  ['фли', 12, 8, 4, 0],
  ['скор', 26, 9, 17, 0],
  ['скро', 11, 2, 9, 0],
  ['скры', 17, 9, 8, 0],
  ['лость', 86, 0, 0, 86],
  ['сла', 145, 83, 61, 1],
  ['слан', 8, 3, 5, 0],
  ['тий', 18, 0, 11, 7],
  ['бой', 67, 13, 32, 21],
  ['спла', 25, 7, 18, 0],
  ['спло', 12, 7, 5, 0],
  ['кой', 15, 3, 10, 2],
  ['щность', 16, 0, 0, 16],
  ['спор', 52, 20, 30, 1],
  ['док', 67, 0, 0, 66],
  ['спра', 34, 9, 25, 0],
  ['спре', 49, 1, 48, 0],
  ['дел', 49, 6, 24, 19],
  ['дме', 22, 0, 22, 0],
  ['пя', 101, 70, 31, 0],
  ['спри', 27, 0, 27, 0],
  ['быль', 14, 2, 4, 7],
  ['сприн', 7, 6, 1, 0],
  ['спро', 37, 0, 37, 0],
  ['све', 173, 108, 65, 0],
  ['спу', 45, 10, 35, 0],
  ['ссер', 12, 0, 7, 5],
  ['дечь', 5, 0, 5, 0],
  ['ссла', 7, 0, 7, 0],
  ['ссмы', 8, 0, 8, 0],
  ['слье', 16, 0, 0, 16],
  ['ссре', 5, 0, 5, 0],
  ['ссро', 5, 0, 5, 0],
  ['ссты', 6, 0, 6, 0],
  ['дни', 80, 1, 79, 0],
  ['жесть', 16, 0, 0, 15],
  ['варь', 8, 3, 1, 4],
  ['лочь', 5, 0, 0, 5],
  ['стре', 73, 37, 36, 0],
  ['схи', 17, 7, 10, 0],
  ['сцве', 11, 0, 11, 0],
  ['сче', 26, 7, 19, 0],
  ['счи', 38, 11, 27, 0],
  ['встви', 17, 0, 17, 0],
  ['тунь', 17, 0, 15, 2],
  ['счё', 12, 4, 8, 0],
  ['лом', 67, 8, 39, 19],
  ['нов', 6, 0, 0, 6],
  ['чё', 63, 6, 57, 0],
  ['тло', 24, 0, 22, 2],
  ['бко', 15, 0, 15, 0],
  ['вак', 9, 0, 0, 9],
  ['зань', 68, 0, 67, 1],
  ['класс', 5, 0, 0, 4],
  ['жень', 221, 1, 214, 6],
  ['ект', 14, 0, 0, 14],
  ['драт', 13, 0, 0, 13],
  ['пакт', 5, 0, 2, 2],
  ['бин', 43, 5, 22, 16],
  ['талл', 8, 0, 0, 8],
  ['ллизм', 5, 0, 0, 5],
  ['дуль', 11, 2, 5, 4],
  ['газ', 5, 0, 0, 4],
  ['мус', 14, 0, 0, 13],
  ['ом', 18, 13, 0, 4],
  ['онт', 7, 0, 0, 7],
  ['вре', 43, 14, 29, 0],
  ['зно', 86, 0, 85, 1],
  ['бот', 13, 0, 0, 12],
  ['ос', 5, 0, 0, 5],
  ['тез', 18, 0, 0, 18],
  ['топ', 12, 0, 0, 11],
  ['цикл', 8, 0, 0, 7],
  ['кспе', 10, 0, 10, 0],
  ['рю', 32, 7, 25, 0],
  ['суль', 56, 16, 40, 0],
  ['фат', 12, 0, 0, 11],
  ['бить', 8, 1, 7, 0],
  ['нил', 23, 0, 9, 14],
  ['фто', 34, 8, 26, 0],
  ['линдр', 5, 0, 0, 5],
  ['чел', 13, 12, 1, 0],
  ['кец', 11, 0, 0, 11],
  ['блё', 13, 3, 10, 0],
  ['щен', 15, 0, 15, 0],
  ['щень', 146, 0, 146, 0],
  ['вонь', 6, 0, 5, 0],
  ['рень', 115, 0, 106, 9],
  ['янь', 73, 0, 73, 0],
  ['душь', 16, 0, 16, 0],
  ['зву', 70, 49, 21, 0],
  ['звучь', 5, 0, 5, 0],
  ['лепь', 5, 0, 5, 0],
  ['равь', 5, 0, 5, 0],
  ['личь', 6, 0, 6, 0],
  ['родь', 9, 0, 8, 1],
  ['скло', 12, 8, 4, 0],
  ['стын', 6, 0, 6, 0],
  ['тво', 101, 8, 91, 2],
  ['твор', 44, 1, 40, 3],
  ['смо', 111, 34, 76, 1],
  ['трень', 8, 2, 6, 0],
  ['кий', 16, 0, 8, 7],
  ['каз', 13, 0, 0, 13],
  ['блан', 9, 9, 0, 0],
  ['кизм', 6, 0, 0, 6],
  ['кист', 7, 0, 0, 7],
  ['бласт', 16, 0, 0, 15],
  ['штейн', 5, 0, 0, 4],
  ['блен', 5, 3, 2, 0],
  ['скость', 10, 0, 0, 10],
  ['сна', 54, 6, 41, 7],
  ['снень', 20, 0, 20, 0],
  ['зко', 14, 0, 12, 2],
  ['зость', 7, 0, 0, 7],
  ['блик', 7, 0, 0, 6],
  ['блин', 9, 6, 1, 1],
  ['стань', 23, 0, 22, 1],
  ['нир', 12, 1, 2, 9],
  ['блог', 5, 0, 0, 4],
  ['ггер', 10, 0, 1, 9],
  ['блок', 26, 11, 0, 14],
  ['вста', 9, 6, 3, 0],
  ['плект', 10, 0, 0, 10],
  ['лис', 16, 0, 0, 15],
  ['гнал', 10, 0, 0, 10],
  ['схе', 20, 8, 12, 0],
  ['уз', 9, 0, 0, 9],
  ['блон', 10, 5, 2, 3],
  ['лов', 23, 0, 0, 22],
  ['шень', 125, 0, 117, 8],
  ['блу', 28, 10, 18, 0],
  ['дли', 46, 4, 42, 0],
  ['жда', 24, 0, 15, 9],
  ['блю', 33, 7, 26, 0],
  ['чко', 46, 0, 18, 28],
  ['дце', 18, 0, 16, 2],
  ['минг', 7, 0, 0, 7],
  ['бик', 18, 0, 0, 18],
  ['бок', 37, 0, 0, 36],
  ['брё', 6, 1, 5, 0],
  ['гач', 10, 0, 0, 10],
  ['терь', 17, 1, 12, 4],
  ['миль', 13, 1, 12, 0],
  ['мол', 57, 20, 25, 11],
  ['вда', 15, 5, 5, 5],
  ['тсту', 17, 0, 17, 0],
  ['пни', 26, 1, 25, 0],
  ['знань', 21, 1, 20, 0],
  ['тань', 89, 1, 87, 1],
  ['слов', 10, 0, 0, 10],
  ['век', 7, 0, 0, 6],
  ['дря', 16, 5, 11, 0],
  ['дун', 11, 2, 2, 7],
  ['честь', 31, 0, 0, 30],
  ['дя', 37, 6, 30, 1],
  ['яд', 17, 0, 0, 16],
  ['плав', 7, 0, 0, 6],
  ['лель', 6, 0, 6, 0],
  ['тце', 18, 0, 11, 7],
  ['тли', 66, 0, 66, 0],
  ['вня', 18, 1, 3, 14],
  ['боль', 32, 11, 16, 4],
  ['шак', 7, 0, 0, 7],
  ['рот', 39, 0, 0, 38],
  ['брам', 5, 2, 3, 0],
  ['стень', 15, 1, 11, 3],
  ['дир', 20, 2, 8, 10],
  ['сбра', 8, 4, 4, 0],
  ['жо', 39, 5, 34, 0],
  ['сань', 42, 0, 42, 0],
  ['зя', 25, 6, 16, 3],
  ['вок', 38, 0, 0, 38],
  ['дён', 8, 0, 8, 0],
  ['зда', 54, 1, 42, 11],
  ['здка', 12, 0, 0, 12],
  ['ткры', 12, 0, 12, 0],
  ['ниль', 14, 1, 10, 3],
  ['тме', 14, 1, 13, 0],
  ['тпро', 11, 0, 11, 0],
  ['що', 5, 0, 5, 0],
  ['борь', 8, 1, 7, 0],
  ['тви', 12, 1, 11, 0],
  ['линг', 9, 0, 0, 9],
  ['шняк', 10, 0, 0, 10],
  ['язнь', 5, 0, 0, 5],
  ['зиль', 6, 1, 4, 1],
  ['йон', 9, 0, 0, 9],
  ['кёр', 10, 0, 3, 7],
  ['сель', 59, 31, 17, 10],
  ['бран', 13, 10, 3, 0],
  ['два', 18, 9, 8, 1],
  ['хта', 18, 0, 1, 17],
  ['бур', 90, 45, 31, 13],
  ['жец', 29, 0, 0, 29],
  ['дма', 14, 0, 14, 0],
  ['йор', 14, 2, 6, 6],
  ['эр', 39, 26, 7, 5],
  ['чли', 9, 0, 9, 0],
  ['брань', 6, 0, 5, 0],
  ['любь', 23, 0, 23, 0],
  ['ун', 29, 10, 8, 11],
  ['коль', 47, 11, 25, 11],
  ['фал', 21, 3, 0, 17],
  ['хма', 14, 1, 9, 4],
  ['шно', 5, 0, 4, 1],
  ['шпиль', 8, 2, 0, 5],
  ['вно', 59, 1, 56, 2],
  ['згли', 6, 0, 6, 0],
  ['вым', 6, 5, 1, 0],
  ['пел', 18, 2, 10, 6],
  ['хун', 6, 3, 0, 3],
  ['диль', 40, 0, 37, 3],
  ['бром', 16, 2, 13, 0],
  ['род', 41, 0, 2, 38],
  ['мэ', 15, 4, 11, 0],
  ['брон', 22, 18, 3, 1],
  ['хит', 9, 1, 0, 7],
  ['вмо', 46, 0, 46, 0],
  ['шю', 15, 2, 12, 1],
  ['бру', 41, 25, 16, 0],
  ['кит', 5, 0, 0, 4],
  ['клин', 12, 4, 1, 6],
  ['сча', 19, 6, 13, 0],
  ['бры', 29, 15, 14, 0],
  ['жей', 5, 0, 4, 1],
  ['зга', 17, 0, 10, 6],
  ['кун', 49, 11, 18, 19],
  ['кунь', 8, 0, 7, 1],
  ['брю', 27, 24, 3, 0],
  ['зжань', 5, 0, 5, 0],
  ['хач', 7, 0, 0, 6],
  ['бря', 18, 4, 14, 0],
  ['цань', 12, 0, 12, 0],
  ['бна', 11, 0, 10, 1],
  ['бно', 18, 0, 18, 0],
  ['дда', 9, 0, 7, 2],
  ['тля', 8, 0, 5, 2],
  ['дра', 151, 46, 83, 22],
  ['зун', 5, 0, 1, 4],
  ['буй', 10, 7, 0, 2],
  ['вол', 81, 58, 16, 6],
  ['кво', 14, 6, 6, 2],
  ['ккер', 6, 0, 0, 6],
  ['кля', 27, 15, 6, 6],
  ['лат', 24, 0, 0, 23],
  ['линь', 8, 1, 1, 5],
  ['чна', 19, 0, 19, 0],
  ['рань', 62, 0, 59, 2],
  ['пря', 96, 38, 56, 1],
  ['жон', 18, 6, 8, 4],
  ['зей', 12, 3, 5, 4],
  ['бун', 22, 15, 3, 4],
  ['свер', 89, 79, 9, 1],
  ['скан', 16, 12, 3, 0],
  ['цлер', 6, 0, 1, 5],
  ['эль', 11, 7, 1, 2],
  ['вчик', 16, 0, 0, 16],
  ['взры', 18, 13, 5, 0],
  ['зём', 13, 0, 1, 12],
  ['сак', 10, 0, 0, 9],
  ['рун', 17, 1, 13, 3],
  ['тса', 9, 0, 8, 1],
  ['тыл', 5, 0, 4, 0],
  ['тыль', 6, 0, 4, 2],
  ['фет', 5, 0, 0, 5],
  ['стец', 7, 0, 0, 7],
  ['хте', 10, 0, 10, 0],
  ['хто', 24, 0, 24, 0],
  ['чет', 10, 0, 0, 10],
  ['тавр', 5, 0, 0, 5],
  ['чиль', 10, 0, 10, 0],
  ['шель', 14, 7, 1, 6],
  ['сай', 9, 7, 2, 0],
  ['дло', 20, 0, 16, 4],
  ['бык', 5, 0, 0, 4],
  ['ик', 7, 0, 1, 6],
  ['бэ', 6, 4, 2, 0],
  ['кус', 18, 1, 1, 15],
  ['бю', 23, 16, 7, 0],
  ['вет', 20, 0, 0, 20],
  ['дже', 10, 4, 6, 0],
  ['бя', 28, 2, 26, 0],
  ['вё', 5, 2, 3, 0],
  ['дры', 9, 3, 6, 0],
  ['вёр', 24, 2, 19, 3],
  ['ткость', 12, 0, 0, 12],
  ['тый', 8, 0, 0, 8],
  ['вай', 15, 5, 8, 2],
  ['оль', 17, 7, 1, 9],
  ['ол', 23, 2, 8, 13],
  ['пресс', 23, 17, 0, 5],
  ['шил', 18, 0, 18, 0],
  ['мметр', 5, 0, 0, 5],
  ['дань', 61, 0, 59, 1],
  ['лун', 8, 3, 0, 5],
  ['сго', 11, 6, 5, 0],
  ['спол', 30, 2, 28, 0],
  ['яль', 23, 0, 22, 1],
  ['дал', 21, 3, 11, 7],
  ['нак', 6, 0, 0, 6],
  ['вян', 10, 0, 9, 1],
  ['скул', 6, 1, 2, 3],
  ['зет', 6, 0, 0, 5],
  ['ватт', 8, 1, 1, 5],
  ['фель', 29, 14, 8, 7],
  ['фля', 16, 5, 8, 3],
  ['хла', 32, 11, 21, 0],
  ['гто', 5, 0, 5, 0],
  ['вли', 85, 6, 79, 0],
  ['вде', 6, 4, 2, 0],
  ['влё', 6, 0, 6, 0],
  ['бма', 14, 0, 14, 0],
  ['бхо', 8, 0, 8, 0],
  ['стинг', 8, 0, 0, 7],
  ['дёр', 30, 1, 16, 12],
  ['дом', 39, 10, 20, 8],
  ['ведь', 7, 2, 0, 5],
  ['зень', 12, 0, 6, 6],
  ['вей', 17, 3, 11, 3],
  ['кша', 12, 2, 2, 8],
  ['речь', 17, 0, 15, 1],
  ['росс', 5, 0, 0, 4],
  ['стю', 16, 2, 14, 0],
  ['кол', 78, 35, 25, 17],
  ['пед', 6, 0, 0, 6],
  ['вель', 24, 10, 9, 5],
  ['вул', 13, 8, 4, 1],
  ['вел', 5, 2, 2, 1],
  ['вен', 71, 21, 48, 2],
  ['спан', 8, 1, 7, 0],
  ['дор', 41, 5, 10, 26],
  ['рик', 44, 0, 0, 44],
  ['эл', 6, 1, 3, 2],
  ['бняк', 5, 0, 0, 5],
  ['тён', 7, 0, 7, 0],
  ['щань', 15, 0, 15, 0],
  ['няк', 8, 0, 0, 8],
  ['добь', 12, 0, 12, 0],
  ['чень', 214, 0, 210, 4],
  ['текс', 6, 0, 0, 5],
  ['тел', 16, 7, 3, 6],
  ['хво', 37, 16, 21, 0],
  ['шей', 7, 2, 4, 1],
  ['тлю', 5, 0, 5, 0],
  ['град', 7, 0, 0, 6],
  ['гляд', 5, 0, 0, 5],
  ['лаз', 10, 0, 0, 9],
  ['вес', 15, 0, 0, 14],
  ['вспа', 6, 5, 1, 0],
  ['сну', 6, 1, 5, 0],
  ['стал', 9, 3, 5, 1],
  ['твле', 8, 0, 8, 0],
  ['бни', 34, 0, 34, 0],
  ['чёт', 14, 0, 0, 13],
  ['тхо', 7, 0, 7, 0],
  ['тша', 7, 0, 3, 4],
  ['чёр', 10, 5, 5, 0],
  ['шаль', 9, 1, 6, 1],
  ['шань', 12, 1, 11, 0],
  ['ял', 17, 1, 14, 1],
  ['вжи', 5, 5, 0, 0],
  ['вза', 53, 49, 4, 0],
  ['верь', 14, 0, 13, 1],
  ['склю', 5, 0, 5, 0],
  ['мань', 46, 8, 37, 1],
  ['бмен', 14, 0, 1, 13],
  ['мощь', 7, 0, 0, 6],
  ['вра', 63, 16, 43, 4],
  ['блень', 39, 0, 39, 0],
  ['свя', 48, 26, 22, 0],
  ['связь', 8, 0, 0, 7],
  ['зги', 22, 0, 22, 0],
  ['взве', 7, 7, 0, 0],
  ['жань', 36, 0, 36, 0],
  ['взды', 6, 6, 0, 0],
  ['взи', 5, 3, 2, 0],
  ['здань', 19, 2, 17, 0],
  ['слость', 5, 0, 0, 5],
  ['хле', 65, 41, 24, 0],
  ['хлень', 5, 0, 5, 0],
  ['взы', 10, 9, 1, 0],
  ['скань', 29, 0, 28, 0],
  ['взя', 8, 8, 0, 0],
  ['тко', 28, 0, 26, 2],
  ['хот', 6, 0, 0, 6],
  ['зонд', 6, 0, 0, 5],
  ['шум', 6, 3, 0, 2],
  ['гиль', 18, 8, 7, 2],
  ['гонь', 5, 1, 0, 4],
  ['даль', 52, 17, 28, 6],
  ['диск', 6, 1, 0, 4],
  ['пульс', 9, 0, 0, 8],
  ['пам', 20, 14, 6, 0],
  ['йер', 14, 0, 6, 8],
  ['плен', 9, 1, 5, 2],
  ['дста', 31, 0, 31, 0],
  ['дукт', 17, 0, 0, 17],
  ['жим', 16, 0, 8, 7],
  ['сю', 15, 11, 4, 0],
  ['жет', 7, 0, 0, 7],
  ['текст', 8, 1, 0, 6],
  ['фильм', 14, 1, 0, 12],
  ['нель', 19, 1, 5, 13],
  ['рог', 11, 0, 0, 10],
  ['нян', 8, 2, 6, 0],
  ['кель', 27, 10, 6, 11],
  ['вля', 24, 0, 13, 11],
  ['пий', 10, 0, 3, 7],
  ['сент', 5, 4, 0, 1],
  ['чель', 5, 0, 4, 1],
  ['плё', 16, 2, 14, 0],
  ['сму', 14, 11, 3, 0],
  ['сцин', 8, 5, 0, 3],
  ['хля', 8, 1, 6, 1],
  ['хре', 14, 8, 6, 0],
  ['сул', 12, 6, 2, 4],
  ['дсе', 30, 0, 30, 0],
  ['премь', 10, 6, 4, 0],
  ['вич', 12, 1, 0, 11],
  ['чу', 158, 103, 55, 0],
  ['шне', 5, 2, 2, 1],
  ['вкла', 6, 6, 0, 0],
  ['дчи', 44, 0, 44, 0],
  ['вкли', 5, 5, 0, 0],
  ['вклю', 6, 4, 2, 0],
  ['вко', 11, 2, 6, 3],
  ['вкра', 7, 7, 0, 0],
  ['плень', 64, 0, 64, 0],
  ['вку', 6, 4, 2, 0],
  ['тда', 14, 0, 14, 0],
  ['глав', 5, 0, 0, 5],
  ['вло', 16, 3, 13, 0],
  ['вме', 28, 9, 19, 0],
  ['вне', 30, 18, 12, 0],
  ['школь', 7, 3, 4, 0],
  ['вну', 19, 12, 7, 0],
  ['гну', 9, 3, 5, 0],
  ['лив', 8, 0, 0, 8],
  ['люб', 16, 0, 0, 16],
  ['мой', 37, 5, 31, 1],
  ['тбор', 12, 0, 9, 3],
  ['твод', 12, 0, 0, 12],
  ['пад', 12, 0, 0, 12],
  ['пой', 15, 4, 4, 7],
  ['росль', 5, 0, 0, 5],
  ['сброс', 6, 0, 0, 5],
  ['свин', 18, 13, 5, 0],
  ['смотр', 11, 0, 0, 10],
  ['бже', 20, 0, 20, 0],
  ['бжень', 10, 0, 10, 0],
  ['сток', 38, 0, 0, 37],
  ['пал', 26, 2, 12, 11],
  ['дру', 41, 17, 24, 0],
  ['дян', 5, 0, 5, 0],
  ['ной', 9, 0, 4, 5],
  ['жак', 6, 0, 0, 6],
  ['ждё', 9, 0, 9, 0],
  ['згон', 5, 0, 3, 2],
  ['здви', 5, 0, 5, 0],
  ['здер', 9, 0, 9, 0],
  ['зды', 9, 0, 9, 0],
  ['зле', 17, 0, 17, 0],
  ['зди', 8, 0, 8, 0],
  ['зму', 9, 0, 9, 0],
  ['чий', 19, 0, 0, 18],
  ['зок', 25, 0, 0, 25],
  ['гарь', 6, 0, 0, 5],
  ['дыр', 6, 1, 5, 0],
  ['дырь', 5, 0, 0, 5],
  ['изъ', 15, 10, 5, 0],
  ['зуб', 11, 0, 0, 10],
  ['кни', 47, 36, 10, 1],
  ['лос', 6, 1, 0, 5],
  ['стель', 14, 2, 5, 6],
  ['сян', 5, 0, 5, 0],
  ['лох', 7, 0, 0, 6],
  ['тпу', 11, 0, 11, 0],
  ['жёр', 15, 2, 4, 9],
  ['жё', 26, 4, 22, 0],
  ['прос', 7, 0, 0, 7],
  ['рох', 6, 0, 0, 6],
  ['силь', 31, 6, 24, 0],
  ['чать', 7, 0, 2, 5],
  ['чун', 5, 1, 0, 4],
  ['скли', 10, 1, 9, 0],
  ['скре', 44, 19, 25, 0],
  ['нань', 19, 0, 19, 0],
  ['сста', 17, 0, 17, 0],
  ['чно', 8, 0, 8, 0],
  ['схва', 9, 3, 6, 0],
  ['сход', 6, 0, 0, 5],
  ['сше', 5, 0, 5, 0],
  ['леть', 24, 0, 24, 0],
  ['ссник', 12, 0, 0, 12],
  ['ссни', 12, 0, 12, 0],
  ['тлеть', 8, 0, 8, 0],
  ['стишь', 6, 0, 6, 0],
  ['яж', 9, 0, 0, 9],
  ['тря', 42, 22, 20, 0],
  ['впе', 7, 6, 1, 0],
  ['тлень', 7, 1, 6, 0],
  ['впи', 7, 7, 0, 0],
  ['врем', 10, 10, 0, 0],
  ['слень', 20, 0, 19, 1],
  ['вру', 13, 11, 2, 0],
  ['вса', 7, 5, 2, 0],
  ['все', 42, 38, 4, 0],
  ['знай', 10, 0, 10, 0],
  ['ружь', 6, 3, 3, 0],
  ['хва', 45, 15, 29, 1],
  ['жже', 12, 1, 11, 0],
  ['жжень', 6, 1, 5, 0],
  ['слы', 8, 3, 5, 0],
  ['мли', 19, 0, 19, 0],
  ['вски', 5, 5, 0, 0],
  ['пань', 51, 0, 47, 4],
  ['мле', 17, 4, 13, 0],
  ['всо', 5, 2, 3, 0],
  ['вспо', 7, 5, 2, 0],
  ['вспу', 5, 5, 0, 0],
  ['встре', 5, 5, 0, 0],
  ['чный', 5, 0, 0, 5],
  ['вся', 5, 2, 3, 0],
  ['вте', 5, 2, 3, 0],
  ['зря', 13, 1, 12, 0],
  ['втя', 6, 6, 0, 0],
  ['йе', 6, 3, 2, 1],
  ['вун', 7, 1, 1, 5],
  ['вхо', 5, 3, 2, 0],
  ['вчи', 15, 2, 13, 0],
  ['вши', 8, 6, 2, 0],
  ['брос', 5, 0, 0, 5],
  ['быть', 10, 0, 10, 0],
  ['гиб', 7, 0, 0, 7],
  ['бщик', 25, 0, 0, 25],
  ['дой', 16, 6, 6, 4],
  ['дрё', 5, 1, 4, 0],
  ['грыш', 5, 0, 0, 5],
  ['клад', 15, 0, 0, 14],
  ['клю', 44, 13, 31, 0],
  ['корм', 9, 0, 0, 8],
  ['крой', 11, 1, 6, 3],
  ['куп', 6, 0, 0, 6],
  ['пщи', 15, 0, 15, 0],
  ['сел', 23, 7, 7, 9],
  ['ны', 19, 2, 15, 2],
  ['пил', 21, 3, 16, 2],
  ['плыв', 6, 0, 0, 6],
  ['плы', 7, 2, 5, 0],
  ['пот', 12, 0, 0, 11],
  ['сты', 54, 9, 45, 0],
  ['прям', 6, 0, 6, 0],
  ['пуск', 17, 0, 0, 16],
  ['рост', 7, 0, 0, 6],
  ['руб', 9, 0, 0, 9],
  ['свист', 5, 0, 0, 4],
  ['сво', 75, 49, 26, 0],
  ['сед', 5, 0, 0, 5],
  ['скаль', 15, 4, 10, 1],
  ['горь', 13, 6, 6, 1],
  ['лань', 18, 0, 17, 0],
  ['стил', 13, 3, 7, 3],
  ['стрел', 13, 3, 4, 6],
  ['ступ', 7, 0, 0, 7],
  ['сыл', 10, 0, 8, 2],
  ['звле', 11, 0, 11, 0],
  ['звлень', 5, 0, 5, 0],
  ['хват', 11, 0, 0, 10],
  ['цвет', 17, 0, 0, 16],
  ['цве', 50, 36, 14, 0],
  ['чле', 15, 7, 8, 0],
  ['чур', 10, 6, 4, 0],
  ['швы', 7, 1, 6, 0],
  ['шу', 67, 53, 13, 0],
  ['вью', 11, 7, 2, 2],
  ['вяз', 5, 0, 0, 4],
  ['зкость', 6, 0, 0, 6],
  ['зми', 9, 0, 9, 0],
  ['вязь', 9, 4, 0, 4],
  ['мич', 7, 0, 0, 7],
  ['тус', 12, 0, 1, 11],
  ['ныш', 14, 0, 0, 14],
  ['дость', 12, 0, 0, 12],
  ['ват', 8, 0, 2, 6],
  ['шла', 23, 20, 3, 0],
  ['сий', 11, 0, 9, 2],
  ['ллу', 7, 0, 7, 0],
  ['лоп', 6, 0, 0, 6],
  ['крыть', 8, 0, 8, 0],
  ['зай', 10, 8, 1, 1],
  ['дбо', 6, 0, 6, 0],
  ['бщи', 13, 0, 13, 0],
  ['фю', 10, 3, 7, 0],
  ['рер', 6, 0, 0, 6],
  ['пец', 19, 0, 0, 19],
  ['мал', 22, 8, 11, 3],
  ['гви', 19, 4, 15, 0],
  ['гво', 16, 10, 6, 0],
  ['здок', 5, 0, 0, 5],
  ['кза', 8, 0, 8, 0],
  ['хорд', 6, 0, 0, 6],
  ['эдр', 19, 0, 0, 19],
  ['ктар', 5, 0, 3, 2],
  ['литр', 7, 0, 0, 6],
  ['гем', 5, 2, 1, 1],
  ['лим', 28, 12, 11, 5],
  ['ракс', 7, 0, 0, 7],
  ['марш', 6, 3, 0, 2],
  ['рор', 13, 1, 7, 5],
  ['сек', 7, 0, 0, 7],
  ['штаб', 10, 8, 0, 1],
  ['шта', 28, 22, 5, 1],
  ['объ', 45, 32, 13, 0],
  ['цесс', 10, 0, 0, 10],
  ['рой', 11, 3, 3, 4],
  ['див', 5, 0, 0, 4],
  ['хер', 18, 3, 7, 7],
  ['гант', 5, 0, 0, 5],
  ['дде', 9, 0, 9, 0],
  ['стин', 25, 1, 15, 9],
  ['тне', 17, 0, 17, 0],
  ['ксид', 8, 0, 0, 8],
  ['зат', 6, 0, 0, 6],
  ['ллур', 7, 0, 6, 1],
  ['спы', 18, 0, 18, 0],
  ['кись', 13, 0, 0, 13],
  ['пульт', 5, 0, 0, 4],
  ['гим', 10, 10, 0, 0],
  ['арх', 7, 0, 0, 7],
  ['кей', 14, 5, 3, 6],
  ['паль', 49, 17, 31, 0],
  ['звук', 6, 0, 0, 5],
  ['смос', 9, 0, 0, 9],
  ['стран', 18, 4, 14, 0],
  ['сом', 13, 10, 2, 0],
  ['шар', 24, 22, 0, 1],
  ['рэ', 28, 5, 23, 0],
  ['кстен', 5, 0, 5, 0],
  ['пно', 16, 0, 16, 0],
  ['зёр', 17, 1, 9, 7],
  ['уст', 6, 0, 0, 6],
  ['стаз', 5, 0, 0, 4],
  ['сфит', 6, 0, 0, 6],
  ['псо', 18, 2, 15, 1],
  ['гир', 9, 5, 1, 3],
  ['лян', 14, 0, 13, 0],
  ['гит', 17, 0, 0, 16],
  ['тов', 5, 0, 0, 5],
  ['влен', 5, 0, 5, 0],
  ['сдо', 6, 3, 3, 0],
  ['сжи', 16, 11, 5, 0],
  ['лус', 11, 0, 0, 11],
  ['дкость', 6, 0, 0, 6],
  ['глас', 5, 0, 0, 4],
  ['сный', 5, 0, 0, 5],
  ['глень', 5, 0, 4, 0],
  ['глот', 6, 0, 0, 5],
  ['пыш', 5, 0, 0, 5],
  ['харь', 10, 2, 0, 8],
  ['хость', 5, 0, 0, 5],
  ['глы', 5, 5, 0, 0],
  ['глю', 7, 6, 1, 0],
  ['гнё', 5, 1, 4, 0],
  ['дко', 45, 0, 44, 1],
  ['гной', 6, 2, 1, 2],
  ['гот', 5, 0, 0, 4],
  ['дик', 25, 0, 0, 25],
  ['бчик', 9, 0, 0, 9],
  ['ядь', 6, 0, 3, 3],
  ['гом', 5, 3, 0, 2],
  ['мун', 10, 7, 2, 1],
  ['доль', 10, 5, 3, 2],
  ['кокк', 13, 0, 0, 12],
  ['рар', 6, 0, 5, 1],
  ['пак', 12, 0, 0, 11],
  ['рел', 10, 5, 4, 1],
  ['жил', 16, 9, 3, 4],
  ['хоз', 11, 0, 0, 11],
  ['лач', 5, 0, 0, 5],
  ['сза', 5, 0, 5, 0],
  ['фте', 48, 0, 48, 0],
  ['тент', 12, 0, 0, 11],
  ['шли', 34, 17, 17, 0],
  ['дслу', 6, 0, 6, 0],
  ['дарт', 5, 0, 1, 4],
  ['чре', 30, 17, 13, 0],
  ['сэ', 5, 2, 3, 0],
  ['хель', 6, 2, 0, 4],
  ['дус', 8, 0, 0, 8],
  ['толь', 6, 2, 0, 3],
  ['нул', 6, 0, 5, 1],
  ['фик', 10, 0, 0, 10],
  ['фство', 7, 0, 2, 5],
  ['бень', 8, 0, 2, 6],
  ['бне', 7, 0, 7, 0],
  ['грел', 5, 1, 2, 2],
  ['чне', 7, 0, 7, 0],
  ['вна', 10, 0, 4, 6],
  ['грим', 5, 2, 1, 1],
  ['гром', 6, 2, 2, 1],
  ['сфа', 16, 2, 14, 0],
  ['тпра', 15, 0, 15, 0],
  ['зчи', 19, 0, 19, 0],
  ['грун', 16, 13, 2, 1],
  ['ппи', 16, 0, 12, 4],
  ['гры', 26, 9, 17, 0],
  ['тца', 5, 0, 0, 5],
  ['зну', 10, 0, 10, 0],
  ['бча', 6, 0, 6, 0],
  ['яй', 15, 13, 1, 1],
  ['чай', 23, 8, 8, 6],
  ['ур', 28, 18, 7, 3],
  ['дай', 13, 7, 4, 2],
  ['тча', 14, 0, 13, 1],
  ['цан', 5, 1, 2, 2],
  ['дца', 25, 0, 23, 2],
  ['брачь', 7, 0, 7, 0],
  ['тёс', 9, 0, 0, 8],
  ['точь', 5, 0, 5, 0],
  ['иль', 18, 6, 11, 1],
  ['двой', 10, 9, 0, 1],
  ['двор', 14, 11, 2, 0],
  ['цка', 5, 0, 3, 2],
  ['дву', 76, 75, 1, 0],
  ['смы', 25, 12, 13, 0],
  ['хме', 9, 5, 4, 0],
  ['хря', 10, 8, 2, 0],
  ['хсо', 8, 0, 8, 0],
  ['член', 9, 3, 0, 5],
  ['шир', 7, 3, 1, 3],
  ['гте', 6, 0, 6, 0],
  ['тид', 6, 0, 0, 6],
  ['дек', 7, 0, 0, 6],
  ['данс', 5, 0, 0, 5],
  ['крет', 9, 0, 6, 3],
  ['кстра', 20, 0, 19, 1],
  ['кстро', 5, 0, 5, 0],
  ['плет', 6, 0, 0, 6],
  ['гог', 7, 0, 0, 7],
  ['муль', 45, 27, 17, 1],
  ['нун', 6, 2, 3, 1],
  ['паж', 10, 0, 0, 9],
  ['вце', 9, 0, 7, 2],
  ['жал', 7, 0, 6, 1],
  ['сан', 72, 53, 15, 3],
  ['сква', 15, 12, 3, 0],
  ['скри', 35, 14, 21, 0],
  ['дне', 51, 3, 48, 0],
  ['тский', 5, 0, 0, 5],
  ['тё', 49, 12, 37, 0],
  ['сля', 17, 2, 15, 0],
  ['фект', 7, 0, 0, 7],
  ['фен', 10, 3, 3, 3],
  ['рент', 6, 0, 0, 6],
  ['фло', 45, 34, 11, 0],
  ['цил', 5, 0, 2, 3],
  ['шё', 26, 1, 25, 0],
  ['джаз', 5, 3, 0, 1],
  ['принт', 5, 0, 0, 5],
  ['джер', 7, 1, 2, 4],
  ['джин', 5, 4, 0, 0],
  ['тсу', 6, 1, 4, 1],
  ['цу', 14, 5, 8, 1],
  ['дза', 12, 1, 11, 0],
  ['дзе', 6, 3, 2, 1],
  ['жин', 13, 1, 7, 5],
  ['клаз', 5, 0, 0, 5],
  ['ктик', 9, 0, 0, 9],
  ['тпи', 11, 0, 11, 0],
  ['тсе', 12, 0, 12, 0],
  ['зен', 26, 5, 18, 3],
  ['браз', 5, 0, 0, 5],
  ['дим', 9, 0, 5, 3],
  ['фид', 5, 0, 0, 5],
  ['мю', 13, 10, 2, 0],
  ['од', 11, 0, 0, 11],
  ['плом', 12, 6, 5, 1],
  ['плот', 5, 0, 0, 4],
  ['сба', 9, 5, 3, 1],
  ['скра', 9, 0, 8, 1],
  ['тче', 9, 0, 8, 1],
  ['зий', 12, 0, 8, 4],
  ['фтор', 65, 30, 33, 1],
  ['ффу', 7, 0, 7, 0],
  ['жить', 9, 3, 4, 2],
  ['звон', 10, 1, 4, 4],
  ['сро', 10, 7, 3, 0],
  ['пень', 33, 6, 18, 8],
  ['жно', 24, 0, 24, 0],
  ['ллар', 5, 0, 1, 4],
  ['чей', 11, 0, 6, 5],
  ['счик', 19, 0, 0, 19],
  ['зыв', 5, 0, 0, 5],
  ['дос', 6, 0, 0, 5],
  ['хлость', 12, 0, 0, 12],
  ['цент', 8, 0, 0, 7],
  ['влян', 5, 0, 5, 0],
  ['сва', 36, 25, 10, 1],
  ['ссель', 5, 0, 3, 2],
  ['дубль', 7, 6, 0, 0],
  ['дур', 19, 9, 7, 3],
  ['тыш', 8, 0, 0, 8],
  ['шан', 31, 19, 10, 2],
  ['эт', 5, 0, 0, 5],
  ['дым', 12, 7, 3, 1],
  ['марь', 7, 2, 2, 2],
  ['дья', 9, 9, 0, 0],
  ['врей', 5, 0, 4, 1],
  ['пей', 19, 9, 8, 2],
  ['глась', 8, 0, 8, 0],
  ['аст', 5, 0, 0, 5],
  ['ел', 5, 5, 0, 0],
  ['ссен', 7, 0, 7, 0],
  ['фрей', 7, 4, 3, 0],
  ['жён', 5, 2, 1, 2],
  ['стли', 13, 1, 12, 0],
  ['жар', 13, 6, 4, 2],
  ['жел', 31, 30, 1, 0],
  ['дча', 13, 0, 13, 0],
  ['жий', 7, 0, 0, 7],
  ['тдел', 16, 0, 2, 14],
  ['стко', 6, 0, 6, 0],
  ['стя', 49, 10, 39, 0],
  ['цер', 39, 12, 16, 11],
  ['щу', 40, 8, 32, 0],
  ['жиль', 5, 1, 4, 0],
  ['счёт', 8, 0, 0, 7],
  ['сще', 10, 0, 10, 0],
  ['пня', 6, 0, 1, 5],
  ['жне', 13, 1, 12, 0],
  ['киль', 9, 4, 2, 2],
  ['жор', 16, 2, 7, 6],
  ['чал', 12, 4, 6, 1],
  ['зё', 5, 1, 4, 0],
  ['бве', 12, 0, 12, 0],
  ['днень', 10, 0, 10, 0],
  ['водь', 7, 0, 5, 2],
  ['ром', 22, 6, 12, 3],
  ['вхоз', 15, 0, 0, 15],
  ['спин', 8, 2, 4, 1],
  ['жать', 5, 0, 5, 0],
  ['зем', 75, 60, 14, 1],
  ['зрень', 11, 1, 10, 0],
  ['слё', 7, 3, 4, 0],
  ['клё', 20, 5, 15, 0],
  ['клей', 28, 14, 13, 0],
  ['выр', 9, 0, 9, 0],
  ['пёр', 15, 1, 7, 7],
  ['пче', 9, 5, 4, 0],
  ['ек', 7, 0, 0, 7],
  ['кром', 10, 5, 3, 2],
  ['лесь', 8, 0, 8, 0],
  ['май', 13, 7, 2, 3],
  ['мах', 5, 0, 0, 4],
  ['дле', 17, 0, 17, 0],
  ['кну', 5, 2, 3, 0],
  ['стрё', 5, 1, 4, 0],
  ['пай', 18, 5, 9, 3],
  ['плю', 26, 22, 4, 0],
  ['пон', 41, 18, 14, 9],
  ['пыл', 5, 1, 1, 2],
  ['сви', 92, 63, 29, 0],
  ['скок', 8, 0, 0, 7],
  ['слон', 5, 1, 1, 2],
  ['смол', 11, 5, 4, 2],
  ['сра', 29, 21, 8, 0],
  ['стрель', 7, 3, 3, 1],
  ['жна', 6, 0, 5, 1],
  ['тёк', 7, 0, 0, 7],
  ['тем', 25, 17, 7, 1],
  ['пля', 18, 3, 10, 5],
  ['умь', 9, 0, 8, 1],
  ['трен', 9, 5, 2, 1],
  ['хлё', 8, 2, 6, 0],
  ['лё', 14, 6, 8, 0],
  ['чёс', 7, 0, 0, 6],
  ['чте', 9, 1, 8, 0],
  ['чтень', 8, 1, 7, 0],
  ['шну', 7, 5, 2, 0],
  ['шпи', 18, 13, 5, 0],
  ['што', 10, 8, 2, 0],
  ['штри', 9, 8, 1, 0],
  ['щёл', 7, 2, 5, 0],
  ['щем', 5, 0, 5, 0],
  ['зва', 15, 1, 13, 1],
  ['звань', 7, 1, 6, 0],
  ['звер', 12, 4, 8, 0],
  ['щер', 15, 3, 8, 4],
  ['ссёр', 5, 0, 0, 5],
  ['звя', 7, 1, 6, 0],
  ['ровь', 6, 0, 6, 0],
  ['вяк', 5, 0, 0, 5],
  ['здра', 21, 10, 10, 1],
  ['коп', 7, 0, 0, 7],
  ['ля', 40, 20, 16, 3],
  ['снар', 6, 4, 2, 0],
  ['байн', 8, 0, 0, 8],
  ['склад', 7, 0, 0, 6],
  ['зим', 6, 3, 2, 1],
  ['бви', 15, 0, 15, 0],
  ['зла', 15, 11, 4, 0],
  ['шлень', 9, 0, 9, 0],
  ['дче', 5, 0, 5, 0],
  ['той', 5, 1, 0, 3],
  ['сад', 8, 0, 0, 7],
  ['зри', 14, 3, 11, 0],
  ['скал', 12, 4, 4, 3],
  ['брень', 11, 0, 11, 0],
  ['ммер', 12, 0, 5, 7],
  ['зык', 7, 0, 0, 6],
  ['зба', 16, 0, 15, 1],
  ['збе', 11, 0, 11, 0],
  ['збор', 14, 0, 13, 1],
  ['збы', 8, 0, 8, 0],
  ['щё', 27, 10, 17, 0],
  ['згна', 5, 0, 5, 0],
  ['ловь', 8, 0, 8, 0],
  ['змель', 11, 0, 11, 0],
  ['змы', 15, 0, 15, 0],
  ['что', 14, 0, 14, 0],
  ['пса', 8, 6, 1, 1],
  ['щре', 5, 0, 5, 0],
  ['зры', 10, 0, 10, 0],
  ['крин', 6, 1, 1, 3],
  ['ктус', 5, 0, 0, 5],
  ['мам', 7, 4, 1, 1],
  ['фар', 37, 30, 7, 0],
  ['прин', 15, 10, 5, 0],
  ['псу', 6, 0, 6, 0],
  ['ццо', 7, 0, 3, 4],
  ['тим', 13, 4, 6, 3],
  ['фай', 6, 2, 3, 0],
  ['трат', 13, 0, 0, 13],
  ['флю', 24, 19, 5, 0],
  ['сбер', 6, 3, 3, 0],
  ['дрик', 5, 0, 0, 5],
  ['споль', 13, 0, 13, 0],
  ['жнень', 6, 0, 6, 0],
  ['ссле', 9, 0, 9, 0],
  ['ссту', 5, 0, 5, 0],
  ['счер', 7, 0, 7, 0],
  ['тэ', 13, 3, 9, 1],
  ['хти', 16, 0, 16, 0],
  ['форм', 6, 1, 0, 5],
  ['зус', 5, 0, 1, 4],
  ['кай', 16, 11, 4, 1],
  ['зой', 5, 0, 1, 4],
  ['лам', 26, 15, 8, 3],
  ['лым', 9, 0, 8, 1],
  ['жный', 5, 0, 0, 5],
  ['мел', 19, 15, 3, 0],
  ['нем', 9, 8, 1, 0],
  ['пус', 9, 0, 0, 9],
  ['фоль', 14, 10, 1, 3],
  ['цел', 12, 4, 7, 1],
  ['перс', 5, 0, 0, 4],
  ['тул', 20, 5, 9, 6],
  ['пок', 15, 0, 0, 15],
  ['пут', 5, 0, 0, 5],
  ['пю', 6, 3, 3, 0],
  ['рай', 21, 14, 3, 3],
  ['над', 5, 0, 0, 5],
  ['ёз', 7, 0, 0, 7],
  ['рур', 8, 1, 7, 0],
  ['тёж', 5, 0, 0, 5],
  ['дбор', 7, 0, 6, 1],
  ['шпо', 6, 3, 2, 1],
  ['ют', 5, 0, 1, 3],
  ['кван', 10, 10, 0, 0],
  ['сда', 21, 12, 9, 0],
  ['чен', 9, 0, 8, 1],
  ['квин', 10, 10, 0, 0],
  ['фий', 5, 0, 1, 4],
  ['фаль', 31, 30, 0, 1],
  ['кеш', 5, 4, 0, 0],
  ['лор', 10, 5, 1, 3],
  ['ддер', 8, 0, 7, 1],
  ['зля', 5, 0, 5, 0],
  ['черк', 7, 0, 0, 7],
  ['тге', 30, 0, 30, 0],
  ['шва', 10, 5, 1, 4],
  ['сей', 36, 27, 7, 2],
  ['сонь', 5, 1, 4, 0],
  ['дби', 11, 0, 11, 0],
  ['цист', 11, 0, 0, 11],
  ['клер', 6, 0, 1, 4],
  ['рос', 9, 0, 0, 9],
  ['стир', 7, 1, 4, 2],
  ['плод', 6, 0, 0, 5],
  ['клюв', 5, 0, 0, 4],
  ['чон', 11, 2, 7, 1],
  ['кня', 8, 8, 0, 0],
  ['бза', 8, 0, 6, 2],
  ['вкость', 7, 0, 0, 7],
  ['дла', 7, 0, 5, 2],
  ['лум', 10, 0, 9, 1],
  ['ммен', 8, 0, 8, 0],
  ['пью', 10, 2, 8, 0],
  ['став', 16, 0, 0, 15],
  ['грев', 11, 0, 0, 11],
  ['гресс', 5, 0, 2, 3],
  ['курс', 6, 0, 0, 5],
  ['плем', 18, 15, 3, 0],
  ['сал', 12, 6, 5, 1],
  ['сист', 8, 0, 0, 8],
  ['траст', 9, 7, 0, 1],
  ['трей', 5, 3, 2, 0],
  ['трре', 9, 0, 9, 0],
  ['сье', 9, 4, 0, 5],
  ['фрон', 18, 17, 1, 0],
  ['пту', 7, 0, 7, 0],
  ['конъ', 11, 11, 0, 0],
  ['ктур', 6, 0, 6, 0],
  ['ший', 7, 0, 0, 7],
  ['пиль', 11, 3, 8, 0],
  ['птиль', 5, 0, 5, 0],
  ['пунь', 9, 0, 8, 1],
  ['пчик', 13, 0, 0, 13],
  ['копь', 7, 5, 1, 0],
  ['фя', 6, 0, 6, 0],
  ['фей', 16, 2, 9, 5],
  ['млень', 7, 1, 6, 0],
  ['тром', 14, 12, 2, 0],
  ['спон', 17, 8, 9, 0],
  ['чем', 9, 5, 3, 1],
  ['шун', 5, 3, 0, 2],
  ['корь', 7, 4, 0, 2],
  ['слой', 11, 1, 3, 6],
  ['стяк', 6, 0, 0, 6],
  ['сырь', 8, 1, 4, 2],
  ['тёл', 8, 1, 3, 4],
  ['шёл', 12, 11, 1, 0],
  ['шел', 13, 10, 3, 0],
  ['шур', 7, 5, 2, 0],
  ['збры', 5, 0, 5, 0],
  ['крей', 9, 8, 1, 0],
  ['цмей', 5, 0, 5, 0],
  ['крем', 14, 10, 3, 0],
  ['крен', 7, 6, 0, 0],
  ['креп', 9, 8, 0, 0],
  ['сталл', 9, 0, 0, 9],
  ['лить', 5, 1, 4, 0],
  ['кроль', 5, 4, 0, 0],
  ['цир', 21, 13, 8, 0],
  ['шиль', 11, 3, 8, 0],
  ['режь', 5, 0, 5, 0],
  ['крым', 5, 5, 0, 0],
  ['крю', 14, 14, 0, 0],
  ['кря', 12, 10, 2, 0],
  ['филл', 6, 0, 0, 6],
  ['пчи', 9, 0, 9, 0],
  ['курь', 5, 5, 0, 0],
  ['старь', 5, 3, 0, 1],
  ['тис', 5, 0, 0, 4],
  ['файл', 6, 1, 0, 4],
  ['гко', 23, 0, 23, 0],
  ['фла', 26, 23, 3, 0],
  ['цет', 10, 0, 0, 10],
  ['пше', 5, 4, 1, 0],
  ['скар', 12, 7, 3, 2],
  ['тга', 6, 0, 6, 0],
  ['флан', 8, 6, 2, 0],
  ['вый', 13, 0, 0, 13],
  ['лейб', 6, 6, 0, 0],
  ['тяй', 6, 0, 4, 2],
  ['пё', 10, 2, 8, 0],
  ['пет', 5, 0, 0, 5],
  ['сби', 13, 8, 5, 0],
  ['сплав', 7, 0, 0, 6],
  ['ксплу', 7, 0, 7, 0],
  ['лже', 23, 23, 0, 0],
  ['хри', 35, 32, 3, 0],
  ['пкость', 6, 0, 0, 6],
  ['пняк', 5, 0, 0, 5],
  ['лир', 6, 1, 1, 4],
  ['твин', 8, 1, 5, 1],
  ['лось', 6, 1, 4, 0],
  ['морь', 5, 0, 5, 0],
  ['льдо', 6, 6, 0, 0],
  ['льно', 25, 25, 0, 0],
  ['дска', 16, 0, 16, 0],
  ['люм', 5, 4, 0, 1],
  ['сши', 25, 7, 18, 0],
  ['сска', 8, 0, 7, 1],
  ['рим', 5, 2, 1, 2],
  ['сем', 21, 18, 3, 0],
  ['сыр', 7, 4, 2, 0],
  ['ссив', 5, 0, 0, 5],
  ['сшта', 7, 0, 7, 0],
  ['трё', 31, 28, 3, 0],
  ['дпо', 19, 0, 19, 0],
  ['ждо', 7, 0, 7, 0],
  ['жду', 12, 0, 12, 0],
  ['путь', 5, 0, 4, 0],
  ['цар', 11, 6, 4, 1],
  ['сфин', 6, 1, 0, 5],
  ['мост', 6, 0, 0, 5],
  ['дши', 8, 0, 8, 0],
  ['скла', 25, 15, 10, 0],
  ['пром', 13, 11, 0, 2],
  ['хим', 14, 12, 2, 0],
  ['мла', 8, 8, 0, 0],
  ['мне', 8, 8, 0, 0],
  ['мно', 88, 88, 0, 0],
  ['зже', 6, 0, 6, 0],
  ['годь', 8, 0, 7, 1],
  ['двин', 5, 0, 4, 1],
  ['суд', 6, 0, 0, 5],
  ['хна', 6, 0, 4, 1],
  ['мочь', 7, 0, 4, 2],
  ['мра', 11, 11, 0, 0],
  ['штра', 6, 5, 0, 1],
  ['мыль', 5, 5, 0, 0],
  ['щел', 10, 7, 3, 0],
  ['гче', 7, 0, 7, 0],
  ['гчень', 6, 0, 6, 0],
  ['тбой', 6, 0, 5, 1],
  ['дба', 8, 0, 8, 0],
  ['двя', 10, 0, 10, 0],
  ['дки', 6, 0, 5, 1],
  ['дку', 8, 0, 8, 0],
  ['дпе', 7, 0, 7, 0],
  ['дпи', 23, 0, 23, 0],
  ['дса', 9, 0, 9, 0],
  ['дсо', 19, 0, 19, 0],
  ['щни', 7, 0, 7, 0],
  ['здни', 5, 0, 5, 0],
  ['най', 15, 10, 4, 1],
  ['пыль', 6, 4, 1, 0],
  ['тстви', 9, 0, 9, 0],
  ['дстве', 13, 0, 13, 0],
  ['хал', 15, 11, 3, 1],
  ['пты', 9, 0, 9, 0],
  ['шлё', 8, 5, 3, 0],
  ['дяй', 8, 0, 5, 3],
  ['жог', 7, 0, 0, 7],
  ['пёк', 6, 0, 0, 6],
  ['ствле', 15, 0, 15, 0],
  ['ствлень', 13, 0, 13, 0],
  ['твра', 6, 0, 6, 0],
  ['ткло', 5, 0, 5, 0],
  ['тры', 12, 0, 12, 0],
  ['тчу', 8, 0, 8, 0],
  ['отъ', 6, 5, 1, 0],
  ['шизм', 5, 0, 0, 5],
  ['дсу', 13, 0, 13, 0],
  ['чти', 9, 3, 6, 0],
  ['судь', 7, 5, 2, 0],
  ['предъ', 6, 4, 2, 0],
  ['змно', 7, 0, 7, 0],
  ['сбы', 5, 4, 1, 0],
  ['свой', 7, 6, 1, 0],
  ['сдер', 6, 5, 1, 0],
  ['серь', 6, 4, 1, 0],
  ['твёр', 5, 1, 4, 0],
  ['вго', 6, 0, 6, 0],
  ['нра', 7, 7, 0, 0],
  ['ныр', 7, 5, 2, 0],
  ['нью', 10, 10, 0, 0],
  ['бва', 6, 0, 6, 0],
  ['бво', 12, 0, 12, 0],
  ['бвя', 6, 0, 6, 0],
  ['безь', 15, 0, 15, 0],
  ['бжа', 6, 0, 5, 1],
  ['бжи', 9, 0, 9, 0],
  ['бман', 6, 0, 4, 2],
  ['бме', 15, 0, 15, 0],
  ['бмер', 6, 0, 4, 2],
  ['бми', 5, 0, 5, 0],
  ['бмо', 15, 0, 15, 0],
  ['бмы', 6, 0, 6, 0],
  ['бщень', 6, 0, 6, 0],
  ['бса', 8, 0, 8, 0],
  ['бсе', 5, 0, 5, 0],
  ['бску', 5, 0, 5, 0],
  ['бсле', 5, 0, 5, 0],
  ['бстру', 6, 0, 6, 0],
  ['бсу', 5, 0, 5, 0],
  ['бте', 5, 0, 5, 0],
  ['бти', 7, 0, 6, 1],
  ['бтя', 5, 0, 5, 0],
  ['бши', 7, 0, 7, 0],
  ['тайм', 5, 2, 0, 2],
  ['фру', 8, 6, 1, 0],
  ['хом', 5, 2, 2, 1],
  ['плость', 5, 0, 0, 5],
  ['сквер', 13, 9, 3, 0],
  ['смоль', 6, 3, 1, 1],
  ['стря', 14, 8, 6, 0],
  ['тби', 9, 2, 7, 0],
  ['тго', 11, 0, 11, 0],
  ['тду', 6, 0, 6, 0],
  ['тку', 6, 0, 6, 0],
  ['тма', 13, 0, 13, 0],
  ['тмы', 5, 0, 5, 0],
  ['тпе', 6, 0, 6, 0],
  ['тси', 5, 0, 4, 1],
  ['тсо', 8, 0, 8, 0],
  ['тста', 7, 0, 7, 0],
  ['тстра', 5, 0, 5, 0],
  ['тще', 13, 8, 5, 0],
  ['фталь', 9, 0, 9, 0],
  ['хру', 17, 12, 5, 0],
  ['шпа', 25, 23, 2, 0],
  ['шпар', 5, 4, 1, 0],
  ['шту', 22, 18, 3, 1],
  ['кма', 5, 0, 5, 0],
  ['цель', 7, 2, 0, 4],
  ['пем', 6, 6, 0, 0],
  ['швар', 6, 3, 3, 0],
  ['кспо', 9, 0, 9, 0],
  ['стун', 5, 0, 0, 5],
  ['щур', 7, 1, 0, 5],
  ['гмо', 8, 0, 8, 0],
  ['скун', 6, 0, 0, 6],
  ['двы', 7, 0, 7, 0],
  ['фок', 6, 4, 0, 1],
  ['дкра', 6, 0, 6, 0],
  ['дмо', 6, 0, 6, 0],
  ['дпа', 10, 0, 10, 0],
  ['дполь', 5, 0, 5, 0],
  ['дпро', 5, 0, 5, 0],
  ['дсве', 5, 0, 5, 0],
  ['дси', 6, 0, 6, 0],
  ['дсла', 5, 0, 5, 0],
  ['дсле', 5, 0, 5, 0],
  ['дсте', 5, 0, 5, 0],
  ['дстре', 7, 0, 7, 0],
  ['бпо', 8, 0, 8, 0],
  ['дсы', 5, 0, 5, 0],
  ['дта', 7, 0, 7, 0],
  ['ксто', 9, 0, 9, 0],
  ['дто', 6, 0, 6, 0],
  ['дха', 6, 0, 6, 0],
  ['дхо', 5, 0, 5, 0],
  ['дше', 12, 0, 12, 0],
  ['здно', 8, 0, 8, 0],
  ['створ', 8, 1, 3, 3],
  ['треть', 9, 7, 1, 0],
  ['разъ', 14, 13, 1, 0],
  ['сум', 12, 9, 1, 2],
  ['шёр', 5, 2, 2, 1],
  ['ям', 8, 5, 2, 0],
  ['фран', 50, 48, 1, 1],
  ['срам', 5, 2, 2, 0],
  ['туй', 5, 2, 2, 1],
  ['сры', 7, 6, 1, 0],
  ['шля', 16, 13, 3, 0],
  ['субъ', 6, 4, 2, 0],
  ['резь', 9, 6, 0, 2],
  ['звёр', 5, 0, 5, 0],
  ['синь', 10, 8, 0, 1],
  ['сколь', 11, 6, 5, 0],
  ['нце', 12, 0, 10, 2],
  ['псал', 10, 8, 2, 0],
  ['псе', 48, 47, 1, 0],
  ['пско', 6, 6, 0, 0],
  ['ктир', 6, 0, 3, 3],
  ['пур', 12, 7, 1, 4],
  ['шки', 9, 2, 7, 0],
  ['штун', 5, 3, 1, 1],
  ['пье', 15, 15, 0, 0],
  ['пья', 5, 5, 0, 0],
  ['склей', 5, 2, 3, 0],
  ['скол', 5, 1, 2, 1],
  ['скру', 14, 10, 4, 0],
  ['спай', 6, 4, 1, 0],
  ['спле', 17, 13, 4, 0],
  ['сплю', 5, 4, 1, 0],
  ['спры', 7, 4, 3, 0],
  ['ссте', 8, 0, 8, 0],
  ['ссы', 14, 6, 8, 0],
  ['цпре', 5, 0, 5, 0],
  ['ржа', 10, 9, 0, 0],
  ['риф', 6, 0, 0, 5],
  ['царь', 5, 3, 0, 1],
  ['ря', 29, 29, 0, 0],
  ['сам', 11, 9, 0, 2],
  ['сбро', 6, 1, 5, 0],
  ['свёр', 8, 8, 0, 0],
  ['хэ', 6, 5, 1, 0],
  ['сгу', 7, 7, 0, 0],
  ['сдви', 5, 5, 0, 0],
  ['кач', 9, 0, 0, 9],
  ['кста', 6, 0, 5, 1],
  ['скир', 7, 6, 0, 1],
  ['скво', 10, 10, 0, 0],
  ['слю', 10, 10, 0, 0],
  ['слюн', 8, 8, 0, 0],
  ['смер', 11, 11, 0, 0],
  ['смор', 7, 7, 0, 0],
  ['смя', 8, 7, 1, 0],
  ['спен', 6, 2, 3, 0],
  ['цпо', 7, 0, 7, 0],
  ['спир', 7, 7, 0, 0],
  ['стрем', 11, 7, 4, 0],
  ['бкон', 5, 0, 5, 0],
  ['съём', 5, 4, 0, 0],
  ['съе', 9, 9, 0, 0],
  ['сюр', 7, 7, 0, 0],
  ['трик', 5, 0, 0, 5],
  ['трам', 11, 10, 1, 0],
  ['трой', 6, 6, 0, 0],
  ['трю', 6, 5, 1, 0],
  ['пко', 16, 0, 16, 0],
  ['тюль', 5, 3, 1, 0],
  ['шам', 11, 10, 1, 0],
  ['флей', 5, 5, 0, 0],
  ['фыр', 5, 4, 1, 0],
  ['хам', 5, 4, 0, 0],
  ['хост', 5, 4, 0, 0],
  ['цы', 13, 13, 0, 0],
  ['шуй', 5, 1, 4, 0],
  ['кспи', 10, 0, 10, 0],
  ['шлю', 7, 7, 0, 0],
  ['шор', 6, 6, 0, 0],
  ['шпун', 6, 6, 0, 0],
  ['шри', 10, 9, 0, 0],
  ['штам', 7, 7, 0, 0],
  ['шти', 5, 5, 0, 0],
  ['штор', 5, 5, 0, 0],
  ['эй', 11, 11, 0, 0],
  ['кзе', 7, 0, 7, 0],
  ['кзо', 19, 0, 19, 0],
  ['кспан', 5, 0, 5, 0],
  ['кспре', 5, 0, 5, 0],
  ['кстре', 5, 0, 5, 0],
  ['ксцен', 5, 0, 5, 0],
];

export function generateCode() {
  const lens = { 2: 30569, 3: 14631, 4: 5701, 5: 1382, 6: 280 };
  let sum = 0;
  for (let i = 2; i <= 5; i++) {
    sum += lens[i];
  }
  let rnd = Math.floor(Math.random() * sum);
  let v = 0;
  let len = 3;
  for (let i = 2; i <= 5; i++) {
    if (v <= rnd && v + lens[i] > rnd) {
      len = i;
      break;
    } else {
      v += lens[i];
    }
  }
  let code;
  do {
    let syllables = syllables_ru;
    let syl = [];
    for (let i = 0; i < len; i++) {
      let tp = i === 0 ? 2 : i === len - 1 ? 4 : 3;
      let sum = 0;
      let last = syl.length ? syl[syl.length - 1] : '';
      for (let j = 0; j < syllables.length; j++) {
        if (i === len - 1 && 'аеёоуыэюя'.indexOf(last[last.length - 1]) > -1 && syllables[j][0][0] === 'е') {
          continue;
        }
        sum += syllables[j][tp];
      }

      let rnd = Math.floor(Math.random() * sum);
      v = 0;
      for (let j = 0; j < syllables.length; j++) {
        if (i === len - 1 && 'аеёоуыэюя'.indexOf(last[last.length - 1]) > -1 && syllables[j][0][0] === 'е') {
          continue;
        }
        if (v <= rnd && v + syllables[j][tp] > rnd) {
          syl.push(syllables[j][0]);
          break;
        } else {
          v += syllables[j][tp];
        }
      }
    }
    code = syl.join('').toLocaleUpperCase();
  } while (code.length < 5);

  return code;
}
