import './styles.scss';

import React from 'react';

export default function Rules() {
  return (
    <div className="rules">
      <div>
        <p className="intro">
          В кооперативной настольной игре <strong>Намёк понял</strong> одному из игроков, исполняющему роль ведущего,
          предстоит отгадать слово по тем подсказкам, которые он получит от других. Остальным игрокам нужно стараться,
          не сговариваясь с товарищами, выбрать понятную, но в то же время оригинальную подсказку, которую никто не
          повторит — ведь одинаковые слова будут удалены из игры!
        </p>
        <h3>1. Выбор слова для угадывания</h3>
        <p>Перед началом игры активные игроки голосованием выбирают одно из пяти слов для угадывания</p>
        <h3>2. Выбор подсказок</h3>
        <p>
          Каждый игрок пишет подсказку, <strong>не договариваясь</strong> с остальными и <strong>не показывая</strong>{' '}
          ее им. Подсказка обязательно должна состоять <strong>из одного слова</strong>
        </p>
        <p>
          <strong>Примечание:</strong> цифра, число, аббревиатура, звукоподражание или специальный символ считаются
          словом. Например, «007» можно использовать как подсказку к слову «Бонд», «дзынь» или «СМС» можно использовать
          как подсказку к слову «телефон», а «$» — как подсказку к слову «Америка»
        </p>
        <h4>Запрещенные подсказки:</h4>
        <ul>
          <li>
            Загаданное слово с изменённой орфографией. Например, «превед» не может быть подсказкой для слова «привет»
          </li>
          <li>
            Загаданное слово, написанное на иностранном языке. Например, «black» не может быть подсказкой для слова
            «чёрный»
          </li>
          <li>Однокоренные слова. Например, «принцесса» не может быть подсказкой для слова «принц»</li>
          <li>Придуманное слово. Например, «тестокрем» не может быть подсказкой для слова «торт»</li>
          <li>
            Слово, совпадающее по звучанию с загаданным словом, но имеющее другое значение. Например, «прут» не может
            быть подсказкой для слова «пруд»
          </li>
        </ul>
        <h3>3. Сравнение подсказок</h3>
        <p>После того как все написали подсказки, игроки сравнивают свои подсказки с подсказками других игроков</p>
        <p>Все одинаковые или запрещённые подсказки должны быть удалены</p>
        <h4>Одинаковые подсказки:</h4>
        <ul>
          <li>Два одинаковых слова. Например, «мышь» и «мышь» — одинаковые подсказки</li>
          <li>Однокоренные слова. Например, «принц» и «принцесса» считаются одинаковыми подсказками</li>
          <li>
            Варианты одного слова: множественное число, другой род и орфографические ошибки не дают оснований считать
            подсказки разными. Например, «принц» и «принцы», «булочник» и «булочница», «философия» и «фелософия» —
            одинаковые подсказки
          </li>
        </ul>
        <p>
          <strong>Примечание:</strong> если все подсказки были удалены, то ход пропускается и начинается новая игра
        </p>
        <h3>4. Догадка</h3>
        <p>
          После удаления всех запрещенных и одинаковых подсказок, у ведущего есть <strong>одна</strong> попытка угадать
          слово
        </p>
        <p>Варианты действия:</p>
        <ul>
          <li>Если ведущий угадал слово, то команда зарабатывает бал</li>
          <li>Если ведущий не угадал слово, то количество раундов в игре уменьшается дополнительно на один</li>
          <li>Ведущий может пропустить слово</li>
        </ul>
        <h3>5. Следующая игра</h3>
        <p>После того как ведущий сделает свой ход, выбирается новый ведущий и игра начинается заново</p>
      </div>
    </div>
  );
}
